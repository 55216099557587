<div
  [ngClass]="{
    'loading-spinner-wrapper': !inline,
    'loading-spinner-inline': inline
  }"
>
  <div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <div class="lds-msg" *ngIf="longWait && !inline">This is taking longer than expected, sorry.  Please wait a little longer or  <a href="#" (click)="refresh()">refresh the page</a>.<br><br>  If the problem persists please get in touch via <a [routerLink]="['/chat', 'support']">Technical Support</a>.</div>
</div>
