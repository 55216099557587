import { UserWeeklyTarget } from './../../models/user-weekly-target.model';
import { StrengthWorkoutService } from "./../../services/strength-workout.service";
import { UserStrengthWorkout } from "./../../models/user-strength-workout.model";
import { Component, OnInit } from "@angular/core";
import { forkJoin } from "rxjs";
import { UserService } from "src/app/auth/service/user.service";
import { DateHelperService } from "src/app/services/date-helper.service";
import { WeeklyTargetService } from "src/app/services/weekly-target.service";
import Swal from 'sweetalert2/src/sweetalert2.js';

@Component({
  selector: "app-strength-workouts",
  templateUrl: "./strength-workouts.component.html",
  styleUrls: ["./strength-workouts.component.scss"],
})
export class StrengthWorkoutsComponent implements OnInit {
  public isLoading = false;
  public allWorkouts!: UserStrengthWorkout[];
  public allWeeklyTargets!:UserWeeklyTarget[];
  public daysHit = 0;
  public weeklyTarget = this.strengthWorkoutService.weeklyTarget;
  public errors: any = [];
  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public currentWeekData!: any[];
  public last4Weeks: Date[] = this.dateHelperService.getLast4tWeeks();
  public last4WeeksData!: any;
  public stats: any;

  public today = this.dateHelperService.getToday();
  public workoutMonths: string[] = [];

  constructor(
    private strengthWorkoutService: StrengthWorkoutService,
    private userService: UserService,
    private dateHelperService: DateHelperService,
    private weeklyTargetService: WeeklyTargetService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    const startDate = this.currentWeek[0];
    const endDate = this.currentWeek[6];
    forkJoin({
      // userWalks: this.walkingService.fetchBetween(startDate, endDate),
      userData: this.userService.get(),
      allWorkouts: this.strengthWorkoutService.fetchAll(),
      allTargets: this.weeklyTargetService.fetchAll(),
    }).subscribe(
      (responseData) => {
        this.isLoading = false;
        // this.userWalks = responseData.userWalks;
        this.allWorkouts = responseData.allWorkouts;
        this.allWeeklyTargets = responseData.allTargets;
        this.currentWeekData = this.strengthWorkoutService.generateWeekArray(
          this.currentWeek,
          this.allWorkouts
        );
        this.last4WeeksData =
          this.strengthWorkoutService.generateLast4WeeksArray(
            this.last4Weeks,
            this.allWorkouts
          );

        this.daysHit = this.strengthWorkoutService.getDaysHit(
          this.currentWeekData
        );
        this.stats = this.strengthWorkoutService.getStats(this.allWorkouts, this.allWeeklyTargets);
        this.workoutMonths = this.strengthWorkoutService.getMonths(this.allWorkouts);
      },
      (error) => {
        this.errors.push(error.message);
      }
    );
  }




  onDeleteEntry(entryId:number){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to undo this",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it',
    }).then((result) => {
      if (result.value) {

        this.strengthWorkoutService.delete(entryId).subscribe((responseData) => {
          Swal.fire('Deleted', 'Your entry has been deleted.', 'success');

          let deleteIndex = -1;
            this.allWorkouts.forEach((workout, workoutIndex)=>{
              if(+workout.user_strength_workout_id === +entryId){
                deleteIndex = workoutIndex;
              }
            });

            if(deleteIndex != -1){
              // splice it
              this.allWorkouts.splice(deleteIndex, 1);
            }

        }, (error)=>{
          this.isLoading = false;
          this.errors.push(error.message);
          Swal.fire('Deleted!', 'There was an error.', 'error');
        });
      }
    });
  }

}
