import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { WalkingService } from "./../../services/walking.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2/src/sweetalert2.js";
import { AchievementService } from "src/app/services/achievement.service";

@Component({
  selector: "app-add-walking-entry",
  templateUrl: "./add-walking-entry.component.html",
  styleUrls: ["./add-walking-entry.component.scss"],
})
export class AddWalkingEntryComponent implements OnInit {
  @ViewChild("form", { static: false }) entryForm?: NgForm;
  submitted = false;
  error?: string;
  maxDate = new Date();
  bsDate = new Date();

  constructor(
    private walkingService: WalkingService,
    private router: Router,
    private achievementService: AchievementService
  ) {}

  ngOnInit(): void {
  }

  onSubmit() {
    this.submitted = true;
    if (!this.entryForm?.valid) {
      Swal.fire({
        icon: "error",
        title: "The form is invalid",
        text: "Please check the form for errors",
        showConfirmButton: true,
      });
    } else {
      // create entry
      const resourceIds = [];
      this.walkingService
        .create(
          +this.entryForm?.value.minutes,
          +this.entryForm?.value.seconds,
          +this.entryForm?.value.difficulty,
          this.entryForm?.value.date
        )
        .subscribe(
          (responseData) => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Entry created",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              toast: true,
            });
            this.router.navigate(["/walking"]);
            this.entryForm?.reset();
            this.achievementService.refreshNotifications();
          },
          (error) => {
            this.error = error.message;
          }
        );
    }
  }
}
