import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { environment } from "./../../../environments/environment";
import { ResourcesService } from "src/app/services/resources.service";

@Component({
  selector: "app-resource-index",
  templateUrl: "./resource-index.component.html",
  styleUrls: ["./resource-index.component.scss"],
})
export class ResourceIndexComponent implements OnInit {
  pages: any;
  resourceId: any;
  resource: any;
  isLoading = false;
  area = "";
  constructor(
    private resourcesService: ResourcesService,
    private route: ActivatedRoute,
    private titleService: Title
  ) {
    if (this.route.snapshot.data["area"]) {
      this.area = this.route.snapshot.data["area"];
      this.resourceId = this.resourcesService.resourceIdMap[this.area];
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.resourcesService
      .fetchResource(this.resourceId)
      .subscribe((responseData) => {
        this.isLoading = false;
        this.pages = responseData.pages;
        this.resource = responseData.resource;
        // set custom HTML title
        this.titleService.setTitle(
          environment.title + " | Resources | " + this.resource.title
        );
      });
  }
}
