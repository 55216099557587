import { UserWalking } from "./../../models/user-walking.model";
import { ActivatedRoute } from "@angular/router";
import { forkJoin } from "rxjs";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { WalkingService } from "./../../services/walking.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2/src/sweetalert2.js";
import * as moment from "moment";
import { AchievementService } from "src/app/services/achievement.service";

@Component({
  selector: "app-edit-walking-entry",
  templateUrl: "./edit-walking-entry.component.html",
  styleUrls: ["./edit-walking-entry.component.scss"],
})
export class EditWalkingEntryComponent implements OnInit {
  @ViewChild("form", { static: false }) entryForm?: NgForm;
  submitted = false;
  error!: string;
  public errors: any = [];
  isLoading = false;
  public entryId!: number;
  public userWalk?: UserWalking;
  maxDate = new Date();
  workoutDate?: Date;

  constructor(
    private walkingService: WalkingService,
    private router: Router,
    private route: ActivatedRoute,
    private achievementService: AchievementService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.entryId = params.id;
      this.isLoading = true;
      forkJoin({
        userWalk: this.walkingService.fetch(this.entryId),
      }).subscribe(
        (responseData) => {
          this.isLoading = false;
          this.userWalk = responseData.userWalk;
          this.workoutDate = moment(this.userWalk.date_walked).toDate();
        },
        (error) => {
          this.errors.push(error.message);
        }
      );
    });
  }

  onSubmit() {
    this.submitted = true;
    if (!this.entryForm?.valid) {
      Swal.fire({
        icon: "error",
        title: "The form is invalid",
        text: "Please check the form for errors",
        showConfirmButton: true,
      });
    } else {
      // update entry
      const resourceIds = [];
      this.walkingService
        .update(
          this.entryId,
          +this.entryForm?.value.minutes,
          +this.entryForm?.value.seconds,
          +this.entryForm?.value.difficulty,
          this.entryForm?.value.date
        )
        .subscribe(
          (responseData) => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Entry updated",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              toast: true,
            });
            this.router.navigate(["/walking", "workouts"]);
            this.entryForm?.reset();
            this.achievementService.refreshNotifications();
          },
          (error) => {
            this.error = error.message;
          }
        );
    }
  }
}
