import { forkJoin } from "rxjs";
import { YogaWorkoutService } from "./../../services/yoga-workout.service";
import { StrengthWorkoutService } from "./../../services/strength-workout.service";
import { WalkingService } from "./../../services/walking.service";
import { ActivatedRoute } from "@angular/router";
import { AchievementService } from "./../../services/achievement.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { UserAchievement } from "src/app/models/user-achievement.model";
import { TabsetComponent } from "ngx-bootstrap/tabs";
import { GoalService } from "src/app/services/goal.service";

@Component({
  selector: "app-achievements-index",
  templateUrl: "./achievements-index.component.html",
  styleUrls: ["./achievements-index.component.scss"],
})
export class AchievementsIndexComponent implements OnInit {
  area = "global";
  isLoading = false;
  weeklyParticipationBadges: UserAchievement[] = [];
  nextParticipationStreak = 3;
  // walking
  walkingWorkoutMilestones: UserAchievement[] = [];
  nextWalkingWorkoutMilestone = "";
  walkingGoals: UserAchievement[] = [];
  nextWalkingGoalAward = "";
  walkingStreaks: UserAchievement[] = [];
  nextWalkingStreak = "";

  // strength
  strengthWorkoutMilestones: UserAchievement[] = [];
  nextStrengthWorkoutMilestone = "";
  strengthGoals: UserAchievement[] = [];
  nextStrengthGoalAward = "";
  strengthStreaks: UserAchievement[] = [];
  nextStrengthStreak = "";

  // yoga
  yogaWorkoutMilestones: UserAchievement[] = [];
  nextYogaWorkoutMilestone = "";
  yogaGoals: UserAchievement[] = [];
  nextYogaGoalAward = "";
  yogaStreaks: UserAchievement[] = [];
  nextYogaStreak = "";

  allAchievements: UserAchievement[] = [];
  uniqueAchievements: UserAchievement[] = [];
  current = {
    walkingWorkouts: 0,
    walkingGoals: 0,
    walkingStreak: 0,
    strengthWorkouts: 0,
    strengthGoals: 0,
    strengthStreak: 0,
    yogaWorkouts: 0,
    yogaGoals: 0,
    yogaStreak: 0,
  };

  constructor(
    private achievementService: AchievementService,
    private route: ActivatedRoute,
    private walkingService: WalkingService,
    private strengthWorkoutService: StrengthWorkoutService,
    private yogaWorkoutService: YogaWorkoutService,
    private goalService: GoalService
  ) {
    if (this.route.snapshot.data["area"]) {
      this.area = this.route.snapshot.data["area"];
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.achievementService.fetchAll().subscribe((responseData) => {
      this.isLoading = false;

      this.allAchievements = responseData;
      this.uniqueAchievements = this.achievementService.getUnique(responseData);

      this.uniqueAchievements.forEach((achievement) => {
        if (
          achievement.area === "walking" &&
          achievement.type === "badge-workouts"
        ) {
          this.walkingWorkoutMilestones.push(achievement);
        }
        if (
          achievement.area === "walking" &&
          achievement.type === "badge-goals"
        ) {
          this.walkingGoals.push(achievement);
        }
        if (
          achievement.area === "walking" &&
          achievement.type === "badge-workout-streak"
        ) {
          this.walkingStreaks.push(achievement);
        }

        if (
          achievement.area === "strength" &&
          achievement.type === "badge-workouts"
        ) {
          this.strengthWorkoutMilestones.push(achievement);
        }
        if (
          achievement.area === "strength" &&
          achievement.type === "badge-goals"
        ) {
          this.strengthGoals.push(achievement);
        }
        if (
          achievement.area === "strength" &&
          achievement.type === "badge-workout-streak"
        ) {
          this.strengthStreaks.push(achievement);
        }

        if (
          achievement.area === "yoga" &&
          achievement.type === "badge-workouts"
        ) {
          this.yogaWorkoutMilestones.push(achievement);
        }
        if (achievement.area === "yoga" && achievement.type === "badge-goals") {
          this.yogaGoals.push(achievement);
        }
        if (
          achievement.area === "yoga" &&
          achievement.type === "badge-workout-streak"
        ) {
          this.yogaStreaks.push(achievement);
        }
        if (achievement.type === "week-streak") {
          this.weeklyParticipationBadges.push(achievement);
        }
      });

      // get nexts
      this.nextWalkingWorkoutMilestone =
        this.achievementService.getNextMilestone(
          "walking",
          "badge-workouts",
          this.allAchievements
        );
      this.nextStrengthWorkoutMilestone =
        this.achievementService.getNextMilestone(
          "strength",
          "badge-workouts",
          this.allAchievements
        );
      this.nextYogaWorkoutMilestone = this.achievementService.getNextMilestone(
        "yoga",
        "badge-workouts",
        this.allAchievements
      );

      this.nextWalkingGoalAward = this.achievementService.getNextMilestone(
        "walking",
        "badge-goals",
        this.allAchievements
      );
      this.nextStrengthGoalAward = this.achievementService.getNextMilestone(
        "strength",
        "badge-goals",
        this.allAchievements
      );
      this.nextYogaGoalAward = this.achievementService.getNextMilestone(
        "yoga",
        "badge-goals",
        this.allAchievements
      );

      this.nextWalkingStreak = this.achievementService.getNextMilestone(
        "walking",
        "badge-workout-streak",
        this.allAchievements
      );
      this.nextStrengthStreak = this.achievementService.getNextMilestone(
        "strength",
        "badge-workout-streak",
        this.allAchievements
      );
      this.nextYogaStreak = this.achievementService.getNextMilestone(
        "yoga",
        "badge-workout-streak",
        this.allAchievements
      );

      // get currents
      forkJoin({
        allGoals: this.goalService.fetchAll(),
        allWalks: this.walkingService.fetchAll(),
        allStrengthWorkouts: this.strengthWorkoutService.fetchAll(),
        allYogaWorkouts: this.yogaWorkoutService.fetchAll(),
      }).subscribe(
        (responseData) => {
          this.current.walkingWorkouts = responseData.allWalks.length;
          this.current.strengthWorkouts =
            responseData.allStrengthWorkouts.length;
          this.current.yogaWorkouts = responseData.allYogaWorkouts.length;
          responseData.allGoals.forEach((goal) => {
            if (goal.status == 1) {
              switch (goal.area) {
                case "walking":
                  this.current.walkingGoals++;
                  break;
                case "strength":
                  this.current.strengthGoals++;
                  break;
                case "yoga":
                  this.current.yogaGoals++;
                  break;
              }
            }
          });

          // do the streaks..
          this.current.walkingStreak = this.walkingService.getCurrentStreak(
            responseData.allWalks
          );
          this.current.strengthStreak =
            this.strengthWorkoutService.getCurrentStreak(
              responseData.allStrengthWorkouts
            );
          this.current.yogaStreak = this.yogaWorkoutService.getCurrentStreak(
            responseData.allYogaWorkouts
          );
        },
        (error) => {}
      );
    });
  }
  achievementCount(area: string, type: string, total: number) {
    let count = 0;
    this.allAchievements.forEach((achievement) => {
      if (
        achievement.type == type &&
        achievement.area == area &&
        achievement.total == total
      ) {
        count++;
      }
    });
    return count;
  }

  getPopoverContent(achievement: UserAchievement) {
    // Awarded for working out {{achievement.total}} days in a row. Awarded {{achievementCount(achievement.area, achievement.type, achievement.total)}} times.

    let html = "";
    if (achievement.type == "week-streak") {
      html =
        html +
        "Awarded for logging in  " +
        achievement.total.toString() +
        " weeks in a row.";
    } else {
      html =
        html +
        "Awarded for working out " +
        achievement.total.toString() +
        " days in a row.";
    }
    const totalawarded = this.achievementCount(
      achievement.area,
      achievement.type,
      achievement.total
    );
    html = html + " Awarded " + totalawarded.toString() + " time";
    if (totalawarded == 1) {
      html = html + ".";
    } else {
      html = html + "s.";
    }
    return html;
  }
}
