import { forkJoin } from "rxjs";
import { UserStrengthWorkout } from "./../../models/user-strength-workout.model";
import { ActivatedRoute } from "@angular/router";
import { StrengthWorkoutService } from "./../../services/strength-workout.service";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { Component, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2/src/sweetalert2.js";
import * as moment from "moment";
import { AchievementService } from "src/app/services/achievement.service";

@Component({
  selector: "app-edit-strength-workout",
  templateUrl: "./edit-strength-workout.component.html",
  styleUrls: ["./edit-strength-workout.component.scss"],
})
export class EditStrengthWorkoutComponent implements OnInit {
  @ViewChild("form", { static: false }) entryForm?: NgForm;
  submitted = false;
  error?: string;
  public errors: any = [];
  isLoading = false;
  public entryId!: number;
  public workout?: UserStrengthWorkout;
  maxDate = new Date();
  workoutDate?: Date;

  constructor(
    private strengthWorkoutService: StrengthWorkoutService,
    private router: Router,
    private route: ActivatedRoute,
    private achievementService: AchievementService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.entryId = params.id;
      this.isLoading = true;
      forkJoin({
        workout: this.strengthWorkoutService.fetch(this.entryId),
      }).subscribe(
        (responseData) => {
          this.isLoading = false;
          this.workout = responseData.workout;
          this.workoutDate = moment(this.workout.workout_date).toDate();
        },
        (error) => {
          this.errors.push(error.message);
        }
      );
    });
  }

  onSubmit() {
    this.submitted = true;

    if (!this.entryForm?.valid) {
      Swal.fire({
        icon: "error",
        title: "The form is invalid",
        text: "Please check the form for errors",
        showConfirmButton: true,
      });
    } else {
      // create entry
      const resourceIds = [];
      this.strengthWorkoutService
        .update(
          this.entryId,
          this.entryForm?.value.workout_date,
          this.entryForm?.value.weight,
          this.entryForm?.value.set1_reps,
          this.entryForm?.value.set2_reps,
          this.entryForm?.value.set3_reps,
          this.entryForm?.value.difficulty
        )
        .subscribe(
          (responseData) => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Entry updated",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              toast: true,
            });
            this.router.navigate(["/strength", "workouts"]);
            this.entryForm?.reset();
            this.achievementService.refreshNotifications();
          },
          (error) => {
            this.error = error.message;
          }
        );
    }
  }
}
