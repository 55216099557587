import { ActivatedRoute, Router } from '@angular/router';

import {
  AfterViewInit,
  Component,
  OnInit,
  NgModule,
  Compiler,
  ViewContainerRef,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from './../../../environments/environment';
import { ResourcesService } from 'src/app/services/resources.service';

@Component({
  selector: 'app-resource-page',
  templateUrl: './resource-page.component.html',
  styleUrls: ['./resource-page.component.scss'],
})
export class ResourcePageComponent implements OnInit {
  pages:any;
  resourceId:any;
  resource:any;
  page:any;
  prevPage:any;
  nextPage:any;
  pageId:any;
  isLoading = false;
  fragment:any;
  area = '';
  constructor(
    private resourcesService: ResourcesService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title
  ) {
    if (this.route.snapshot.data["area"]) {
      this.area = this.route.snapshot.data["area"];
      this.resourceId = this.resourcesService.resourceIdMap[this.area];
    }}

  ngOnInit(): void {
    this.route.fragment.subscribe((fragment) => {
      this.fragment = fragment;
    });
    this.route.params.subscribe((params) => {
      this.pageId = this.route.snapshot.params.id;
      this.isLoading = true;
      this.resourcesService.fetchResource(this.resourceId).subscribe(
        (responseData) => {
          this.isLoading = false;
          this.pages = responseData.pages;
          this.pages.forEach((page:any, pIndex:any) => {
            if (+page.content_page_id === +this.pageId) {
              this.page = page;
              if (pIndex > 0) {
                this.prevPage = this.pages[pIndex - 1];
              } else {
                this.prevPage = null;
              }
              if (pIndex + 1 <= this.pages.length) {
                this.nextPage = this.pages[pIndex + 1];
              } else {
                this.nextPage = null;
              }
            }
          });
          this.resource = responseData.resource;
          // set custom HTML title
          this.titleService.setTitle(
            environment.title + ' | Resources | ' + this.resource.title
          );

          // do we need to scroll?
          setTimeout(() => {
            try {
              if (this.fragment) {
                const element = document.querySelector('#' + this.fragment);
                if (element) {
                  setTimeout(() => {
                    element.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                      inline: 'nearest',
                    });
                  }, 500);
                }
              }
            } catch (e) {}
          }, 500);
        },
        (error) => {}
      );
    });
  }

  onPrev() {
    if (this.prevPage) {
      this.page = null;
      this.router.navigate([
        '/resources',
        this.resourceId,
        this.prevPage.content_page_id,
      ]);
      this.prevPage = null;
      this.nextPage = null;
    }
  }
  onNext() {
    if (this.nextPage) {
      this.page = null;
      this.router.navigate([
        '/resources',
        this.resourceId,
        this.nextPage.content_page_id,
      ]);

      this.prevPage = null;
      this.nextPage = null;
    }
  }

  onContentClick(e: MouseEvent) {
    if (
      e.target instanceof HTMLAnchorElement &&
      e.target.host === location.host
    ) {
      e.preventDefault();
      this.router.navigateByUrl(e.target.pathname + e.target.hash);
    }
  }
}
