<div class="fh bg-light pb-10 content-container">
    <app-page-header
      [title]="'Search'"
      [icon]="'search'"
      [backLink]="['/dashboard']"
    ></app-page-header>
  
<div class="container">
  <div class="row" *ngIf="results">
    <div class="col">
      <p>
        Your search for {{ query }} returned {{ results.total }} result<span
          *ngIf="+results.total != 1"
          >s</span
        >
      </p>

      <div *ngIf="results.resources.length > 0">
        <h4>Resources</h4>
        <ul class="forum-posts">
          <li *ngFor="let result of results.resources" class="search-result">
            <a [routerLink]="getResourceUrl(result)" class="topic-title"
              >{{ result.content_section_title }} > {{ result.title }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

</div>