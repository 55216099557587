import { Router, ActivatedRoute } from "@angular/router";
import { GoalService } from "./../../services/goal.service";
import { NgForm } from "@angular/forms";
import { Component, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2/src/sweetalert2.js";
import { AchievementService } from "src/app/services/achievement.service";

@Component({
  selector: "app-add-goal",
  templateUrl: "./add-goal.component.html",
  styleUrls: ["./add-goal.component.scss"],
})
export class AddGoalComponent implements OnInit {
  @ViewChild("form", { static: false }) entryForm?: NgForm;
  submitted = false;
  error?: string;
  area = "walking";

  constructor(
    private goalService: GoalService,
    private router: Router,
    private route: ActivatedRoute,
    private achievementService: AchievementService
  ) {
    if (this.route.snapshot.data["area"]) {
      this.area = this.route.snapshot.data["area"];
    }
  }

  ngOnInit(): void {}

  onSubmit() {
    this.submitted = true;
    if (!this.entryForm?.valid) {
      Swal.fire({
        icon: "error",
        title: "The form is invalid",
        text: "Please check the form for errors",
        showConfirmButton: true,
      });
    } else {
      // create entry
      const resourceIds = [];
      this.goalService
        .create(this.area, 0, this.entryForm?.value.goal)
        .subscribe(
          (responseData) => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Goal created",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              toast: true,
            });
            this.router.navigate(["/" + this.area]);
            this.entryForm?.reset();
            this.achievementService.refreshNotifications();
          },
          (error) => {
            this.error = error.message;
            this.entryForm?.reset();
          }
        );
    }
  }
}
