<ng-container [ngSwitch]="icon">
  <ng-container *ngSwitchCase="'home'">
    <span class="icon-home app-icon">
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="400.000000pt"
        height="400.000000pt"
        viewBox="0 0 400.000000 400.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
          stroke="none"
        >
          <path
            d="M1935 3986 c-44 -21 -1909 -1894 -1925 -1933 -31 -81 27 -188 115
-212 22 -6 93 -11 157 -11 82 0 119 -4 125 -12 4 -7 10 -396 13 -865 5 -845 5
-852 26 -880 11 -15 38 -38 60 -50 39 -23 40 -23 545 -23 380 0 508 3 517 12
9 9 12 116 12 418 0 223 4 431 10 464 37 228 253 387 475 349 177 -30 316
-171 345 -349 6 -33 10 -241 10 -464 0 -302 3 -409 12 -418 9 -9 137 -12 517
-12 505 0 506 0 545 23 22 12 49 35 60 50 21 28 21 35 26 882 3 550 9 857 15
864 6 5 71 11 145 13 151 5 181 14 224 71 37 48 44 113 18 163 -27 50 -1886
1909 -1925 1924 -38 14 -86 12 -122 -4z"
          />
        </g>
      </svg>
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'walking'">
    <span class="icon-walking app-icon">
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="400.000000pt"
        height="400.000000pt"
        viewBox="0 0 400.000000 400.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
          stroke="none"
        >
          <path
            d="M1793 3855 c-79 -22 -131 -52 -189 -109 -111 -112 -151 -267 -106
          -413 56 -178 211 -293 397 -293 371 0 553 443 291 706 -107 106 -253 146 -393
          109z"
          />
          <path
            d="M1665 2841 c-319 -51 -634 -303 -766 -612 -66 -155 -105 -393 -80
          -486 28 -103 133 -183 240 -183 135 0 250 111 251 240 1 168 92 350 223 450
          63 47 80 49 103 15 30 -45 7 -511 -37 -780 -57 -342 -181 -645 -371 -907 -62
          -85 -80 -129 -80 -198 0 -66 18 -113 62 -164 52 -58 103 -81 185 -80 113 0
          160 35 265 195 133 204 248 444 329 691 50 151 67 152 157 16 133 -202 219
          -376 310 -632 32 -89 65 -164 82 -185 36 -44 107 -82 167 -88 126 -15 253 89
          271 220 17 123 -178 591 -363 869 -94 142 -199 272 -329 408 -55 58 -108 116
          -117 130 -14 21 -17 53 -17 217 l0 193 24 6 c18 4 45 -9 118 -60 128 -89 264
          -154 443 -215 182 -62 235 -66 316 -23 138 72 178 243 85 365 -47 61 -79 79
          -248 136 -184 63 -232 89 -419 226 -155 114 -279 181 -399 216 -83 23 -311 35
          -405 20z"
          />
        </g>
      </svg>
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'strength'">
    <span class="icon-strength app-icon">
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="400.000000pt"
        height="400.000000pt"
        viewBox="0 0 400.000000 400.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
          stroke="none"
        >
          <path
            d="M230 3921 c-169 -30 -196 -40 -210 -77 -12 -31 153 -1027 176 -1062
           27 -42 51 -43 240 -11 186 31 207 38 224 69 16 29 12 70 -21 259 -34 199 -32
           222 23 245 18 8 86 26 151 40 754 169 1609 153 2377 -43 163 -42 190 -57 190
           -107 0 -13 -11 -88 -25 -166 -34 -196 -32 -233 14 -257 10 -6 98 -24 195 -40
           189 -32 213 -31 240 11 23 36 188 1031 176 1063 -5 14 -24 32 -42 40 -32 14
           -322 65 -370 65 -45 0 -69 -40 -89 -151 -21 -112 -37 -145 -74 -155 -14 -3
           -86 9 -172 30 -828 200 -1683 212 -2481 35 -73 -16 -139 -29 -147 -29 -8 0
           -28 12 -45 28 -25 23 -32 40 -43 110 -17 100 -41 133 -96 131 -20 -1 -106 -13
           -191 -28z"
          />
          <path
            d="M995 3195 c-43 -24 -74 -55 -98 -100 -21 -37 -22 -53 -20 -240 1
           -143 6 -223 18 -280 49 -235 155 -438 314 -602 74 -76 195 -171 255 -200 l36
           -17 0 -819 c0 -603 3 -824 12 -840 22 -43 36 -46 195 -47 146 0 153 1 180 24
           l28 24 5 245 c5 223 7 247 24 266 24 27 76 28 107 2 24 -19 24 -21 29 -266 l5
           -247 28 -24 c27 -23 34 -24 180 -24 159 1 173 4 195 47 9 16 12 236 12 836 l0
           815 68 40 c218 130 400 344 482 570 58 158 72 252 74 487 1 207 1 211 -24 254
           -14 24 -42 58 -63 75 -32 26 -48 31 -105 34 -84 4 -143 -23 -187 -86 l-30 -44
           -6 -211 c-7 -243 -15 -288 -73 -412 -89 -189 -253 -326 -461 -386 -32 -9 -95
           -14 -175 -14 -107 1 -136 5 -203 27 -256 86 -446 304 -493 567 -7 43 -13 148
           -14 242 0 154 -2 169 -23 210 -13 24 -43 57 -66 74 -34 24 -54 30 -109 33 -48
           2 -76 -2 -97 -13z"
          />
          <path
            d="M1862 3106 c-132 -49 -221 -144 -263 -280 -15 -50 -13 -180 3 -231
           66 -200 262 -322 464 -288 276 47 424 334 305 589 -32 67 -108 151 -163 180
           -114 59 -238 70 -346 30z"
          />
        </g>
      </svg>
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'yoga'">
    <span class="icon-yoga app-icon">
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="400.000000pt"
        height="400.000000pt"
        viewBox="0 0 400.000000 400.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
          stroke="none"
        >
          <path
            d="M1887 3984 c-91 -22 -167 -64 -233 -130 -245 -244 -180 -646 129
           -799 192 -95 403 -62 561 86 163 152 203 396 98 593 -35 67 -145 177 -207 208
           -108 55 -234 70 -348 42z"
          />
          <path
            d="M1394 2740 c-49 -10 -134 -52 -181 -89 -19 -14 -78 -76 -133 -138
           -84 -94 -109 -116 -167 -145 -64 -31 -74 -33 -173 -33 -92 0 -112 3 -160 26
           -30 14 -88 56 -129 93 -113 102 -198 118 -311 61 -115 -58 -162 -201 -106
           -320 25 -53 127 -158 206 -212 230 -156 523 -196 790 -108 90 30 204 95 278
           159 58 50 72 54 96 30 14 -14 16 -70 16 -474 0 -421 1 -459 18 -479 23 -28
           507 -201 562 -201 55 0 539 173 563 201 16 20 17 58 17 479 0 404 2 460 16
           474 24 24 38 20 96 -30 261 -225 650 -269 968 -109 143 72 295 214 325 305 58
           174 -101 358 -276 321 -67 -15 -79 -22 -162 -98 -40 -37 -97 -78 -127 -92 -48
           -23 -68 -26 -160 -26 -99 0 -109 2 -172 33 -54 26 -84 51 -145 120 -169 189
           -225 230 -342 252 -64 12 -1146 12 -1207 0z"
          />
          <path
            d="M858 1069 c-51 -15 -121 -79 -147 -135 -26 -55 -30 -144 -8 -196 23
           -54 81 -113 135 -136 26 -11 421 -152 877 -312 775 -273 833 -292 882 -287 64
           7 111 28 156 72 129 126 82 344 -89 408 -27 11 -411 147 -854 304 -834 295
           -869 305 -952 282z"
          />
          <path
            d="M2955 1056 c-70 -24 -565 -198 -597 -211 -20 -8 -25 -59 -8 -70 6 -4
           98 -38 205 -75 219 -78 222 -79 290 -130 28 -21 59 -41 71 -44 23 -7 191 50
           267 91 87 48 139 161 117 257 -15 67 -33 99 -82 140 -42 36 -119 66 -167 66
           -14 -1 -57 -11 -96 -24z"
          />
        </g>
      </svg>
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'symptoms'">
    <span class="icon-symptoms app-icon">
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="400.000000pt"
        height="400.000000pt"
        viewBox="0 0 400.000000 400.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
          stroke="none"
        >
          <path
            d="M1935 3986 c-43 -19 -81 -65 -94 -111 -7 -27 -11 -299 -11 -850 -1
           -639 -3 -814 -13 -827 -7 -9 -49 -46 -92 -82 -83 -69 -117 -80 -135 -47 -6 13
           -10 218 -10 596 0 464 -3 582 -14 608 -27 67 -116 114 -187 101 -48 -9 -184
           -84 -276 -153 -460 -344 -783 -974 -923 -1801 -51 -302 -74 -524 -90 -857 -10
           -232 -5 -295 35 -373 58 -113 188 -190 320 -190 141 0 454 127 805 325 120 68
           228 168 272 251 55 102 58 135 58 550 0 210 3 396 6 414 6 26 39 59 191 186
           168 140 188 154 223 154 35 0 55 -14 212 -145 96 -80 181 -159 190 -177 16
           -30 18 -71 18 -423 0 -426 3 -458 58 -559 70 -130 183 -213 527 -385 339 -169
           516 -216 655 -175 132 39 224 140 248 275 12 64 0 383 -23 639 -89 952 -368
           1692 -805 2130 -157 157 -366 299 -466 315 -47 7 -103 -12 -143 -48 -51 -47
           -51 -44 -51 -662 0 -378 -4 -583 -10 -596 -18 -33 -52 -22 -135 47 -43 36 -85
           73 -92 82 -10 13 -12 188 -13 827 0 551 -4 823 -11 850 -26 97 -135 151 -224
           111z"
          />
        </g>
      </svg>
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="'recharge'">
    <span class="icon-recharge app-icon">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 44.8 44.8" style="enable-background:new 0 0 44.8 44.8;" xml:space="preserve">

<g id="Recharge_Logo" transform="translate(1329.412 -737.074)">
	<path id="Path_173" class="st0" d="M-1307,747.6c-0.7,0.7-1.3,1.3-2,2c-0.1,0.1-0.2,0.3-0.1,0.4c0.2,1.5,0.5,2.9,0.7,4.4
		c0.3,2.6,0.5,5.2,0.4,7.9c-0.1,2.2-0.2,4.4-0.3,6.6c-0.1,2.2-0.4,4.3-0.8,6.4c-0.1,0.7-0.4,1.4-0.9,2c-0.6,0.7-1.4,1.2-2.2,1.4
		c-2.7,0.7-5.4,1.3-8.1,2c-1.3,0.3-2.7,0.7-4,1c-1.3,0.4-2.7,0-3.7-0.9c-0.9-0.8-1.4-1.9-1.4-3c0-3,0-5.9,0.1-8.9
		c0.2-4.2,1.2-8.4,3-12.2c2-4.4,4.9-8.4,8.5-11.7c0.3-0.3,0.7-0.6,1-0.9c1-0.9,2.5-1.3,3.8-0.9c0.9,0.2,1.7,0.8,2.2,1.7
		c0.4,0.6,0.6,1.2,0.8,1.8l0.2,0.7c0.2-0.2,0.4-0.4,0.5-0.5c0.3-0.3,0.6-0.7,1-1c0.2-0.2,0.3-0.4,0.2-0.6c0-2.5,0-4.9,0-7.4v-0.8
		c0.7,0,1.3,0,2.1,0v0.6c0,2.5,0,5.1,0,7.6c0,0.3,0.1,0.6,0.3,0.8c0.4,0.4,0.7,0.7,1.1,1.1c0.1,0.1,0.2,0.2,0.3,0.3
		c0.1-0.3,0.2-0.7,0.3-1c0.2-0.4,0.3-0.9,0.5-1.3c1.1-1.9,3.5-2.7,5.4-1.6c0.3,0.1,0.5,0.3,0.8,0.5c1.6,1.4,3,2.9,4.4,4.5
		c4,4.8,6.6,10.5,7.7,16.7c0.3,2,0.5,4.1,0.5,6.1c0,2.1,0,4.3,0,6.4c0,1-0.3,2-1,2.8c-1,1.2-2.6,1.7-4,1.3
		c-3.7-0.9-7.4-1.9-11.2-2.8c-0.3-0.1-0.5-0.1-0.8-0.2c-1.6-0.3-2.8-1.5-3.2-3.1c-0.2-0.7-0.3-1.5-0.4-2.3
		c-0.5-4.1-0.8-8.2-0.8-12.3c0-2.3,0.1-4.6,0.5-6.8c0.2-1.4,0.4-2.9,0.7-4.3c0.1-0.2,0-0.3-0.1-0.4
		C-1305.6,748.9-1306.3,748.3-1307,747.6C-1307,747.6-1307,747.6-1307,747.6z M-1304,762.6c0.1,1.3,0.1,2.6,0.2,3.9
		c0.1,2.4,0.3,4.7,0.6,7.1c0.1,0.5,0.1,1,0.3,1.4c0.2,0.9,0.9,1.6,1.7,1.8c0.7,0.2,1.4,0.4,2.2,0.5c3.2,0.8,6.5,1.6,9.7,2.4
		c0.4,0.1,0.8,0.1,1.2,0c0.9-0.2,1.5-1.1,1.5-2c0-2.3,0-4.7,0-7c0-1.8-0.2-3.6-0.5-5.4c-1.2-6.7-4.5-13-9.3-17.8
		c-0.7-0.7-1.4-1.3-2-1.9c-0.8-0.7-2-0.7-2.8,0.1c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.3-0.3,0.6-0.4,1c-0.7,2.1-1.1,4.3-1.4,6.5
		C-1303.8,756.6-1304,759.6-1304,762.6L-1304,762.6z M-1310,762.6c0-2.7-0.2-5.3-0.5-8c-0.2-2-0.6-4-1.1-6c-0.2-0.8-0.5-1.6-0.8-2.4
		c-0.4-1-1.5-1.5-2.5-1.1c-0.2,0.1-0.5,0.3-0.7,0.4c-0.3,0.3-0.7,0.6-1,0.9c-2.1,1.9-4,4.1-5.5,6.5c-3.3,5-5.1,10.8-5.3,16.8
		c-0.1,2.6,0,5.2,0,7.8c0,1.1,0.9,2.1,2,2.1c0.2,0,0.4,0,0.6-0.1c4-1,7.9-2,11.9-3c0.7-0.1,1.3-0.6,1.5-1.3c0.1-0.3,0.2-0.6,0.2-0.9
		c0.2-1.5,0.4-3,0.5-4.6C-1310.3,767.5-1310.2,765.1-1310,762.6z"/>
	<path id="Path_182" class="st0" d="M-1312.3,757.4c-0.2,0-0.3,0.1-0.4,0.1c-3.4,0.8-6.8,1.7-10.2,2.5c-0.2,0-0.4,0.2-0.5,0.4
		c-0.8,2.1-1.3,4.3-1.6,6.5c-0.1,0.4-0.1,0.9-0.1,1.4l12.9-3.2C-1312,762.5-1312.1,759.9-1312.3,757.4z M-1325.2,770.5
		c0,0.1,0,0.2,0,0.3c0,1.6-0.1,3.3-0.1,4.9c0,0.3,0,0.6,0.1,0.9c0.1,0.6,0.6,1,1.2,0.9c0.1,0,0.1,0,0.2,0c0.3-0.1,0.6-0.2,0.9-0.2
		c2.9-0.7,5.8-1.4,8.7-2.1c0.7-0.1,1.3-0.7,1.4-1.4c0.1-0.4,0.1-0.7,0.1-1.1c0.2-1.7,0.3-3.3,0.5-5c0-0.1,0-0.2,0-0.3L-1325.2,770.5
		z M-1312.5,755.2c0-0.1,0-0.2,0-0.2c-0.2-1.2-0.3-2.3-0.5-3.5c-0.1-0.7-0.3-1.4-0.5-2.1c-0.1-0.3-0.2-0.5-0.4-0.8
		c-0.2-0.4-0.7-0.6-1.2-0.4c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5,0.5-1,0.9-1.4,1.4c-2,2.2-3.6,4.6-4.9,7.2
		c0,0.1-0.1,0.2-0.1,0.3L-1312.5,755.2z"/>
	<path id="Path_183" class="st0" d="M-1301.8,765.5c0.5,0.6,1.2,0.8,2,0.7c0,0.3,0,0.5,0,0.8c0,0.3,0.1,0.6,0.3,0.8
		c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2,0,0.5,0,0.7c0,0.4,0.3,0.7,0.7,0.8c0.1,0,0.2,0,0.3,0c0.6-0.1,1-0.6,1-1.2c0-0.5,0-0.9,0-1.4
		c0-0.2,0.1-0.4,0.2-0.6c0.1-0.1,0.3-0.3,0.4-0.4c0.2-0.2,0.2-0.4,0.1-0.6c0,0-0.1-0.1-0.1-0.1c-0.3-0.3-0.7-0.7-1-1
		c0,0-0.1-0.1-0.1-0.2l0.2-0.2c0.2,0.2,0.4,0.4,0.6,0.6c0.5,0.5,0.8,0.5,1.3-0.1l-0.9-0.9l0.2-0.2c0.2,0.3,0.6,0.4,0.9,0.5
		c0.1,0,0.3,0.2,0.4,0.3c0.3,0.3,0.4,0.3,0.8,0c0.2-0.2,0.5-0.5,0.7-0.8l0.8,0.8c0.1,0.1,0.2,0.2,0.2,0.2c0.3,0.2,0.7,0.1,0.7-0.2
		c0-0.3,0.1-0.6,0.4-0.8c0.2-0.2,0.3-0.5,0.3-0.8c0.2,0,0.4,0,0.7-0.1c0.2-0.1,0.4-0.2,0.6-0.3c0.5,1.5,0.8,3.1,1,4.6
		c-0.3-0.1-0.6-0.1-0.8-0.2c-0.5-0.1-1,0.2-1.2,0.6c-0.2,0.2-0.2,0.5-0.1,0.7c0.1,0.3,0.3,0.4,0.6,0.4c0.1,0,0.2,0,0.3,0
		c0.2,0,0.5,0.1,0.6,0.2c0.2,0.2,0.5,0.3,0.8,0.4c0,0.2,0.1,0.5,0.1,0.9c0,1.9,0.1,3.9,0.1,5.8c0,0.3,0,0.6-0.1,0.9
		c-0.1,0.6-0.6,0.9-1.2,0.8c0,0,0,0-0.1,0c-0.2,0-0.3-0.1-0.5-0.1c-3.1-0.7-6.1-1.5-9.2-2.2c-0.8-0.1-1.3-0.7-1.4-1.5
		c-0.1-0.7-0.2-1.3-0.2-2c-0.2-1.8-0.3-3.6-0.4-5.4C-1301.8,766-1301.8,765.8-1301.8,765.5z M-1297.1,768.4c0.5-0.1,0.6-0.3,0.4-0.9
		C-1297.1,767.6-1297.2,767.8-1297.1,768.4L-1297.1,768.4z M-1292.6,765.3c0.5,0.7,0.8,0.9,1.2,0.7
		C-1291.9,765.8-1292,765.2-1292.6,765.3L-1292.6,765.3z M-1290.7,766.2c0.7,0.1,0.8,0,0.9-0.4
		C-1290.4,765.6-1290.6,765.7-1290.7,766.2L-1290.7,766.2z M-1291.3,765.3c0.4-0.1,0.4-0.2,0.3-0.7
		C-1291.4,764.7-1291.4,764.8-1291.3,765.3L-1291.3,765.3z"/>
	<path id="Path_184" class="st0" d="M-1301.9,764.1c0-0.5,0-0.9,0-1.4c0-2.3,0.1-4.5,0.3-6.8c0.2-1.9,0.5-3.9,0.9-5.8
		c0.1-0.5,0.3-1,0.5-1.4c0.4-0.7,0.9-0.8,1.6-0.3c0.8,0.6,1.5,1.3,2.1,2.1c2.1,2.5,3.9,5.3,5.2,8.2c0.1,0.1,0.1,0.3,0.2,0.4
		c-0.2,0.1-0.5,0.1-0.6-0.1c-0.3-0.4-0.7-0.5-1.2-0.5c-0.2,0-0.3,0-0.5,0c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.4,0.3-0.7,0.3
		c-0.3,0-0.7,0.1-0.9,0.4c-0.1,0.2-0.4,0.3-0.6,0.2c-0.4,0-0.7,0-1.1,0c-0.3,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.5-0.4-0.8-0.3
		c-0.4,0.1-0.8,0.4-1,0.8c0,0.2,0,0.3,0.1,0.5l0.8-0.8l0.2,0.2l-0.6,0.9c-0.5,0-1.1,0-1.2,0.8c-0.3,0.1-0.7,0.2-0.8,0.6
		c-0.1,0.2,0.1,0.4,0.4,0.6c0.1-0.2,0.2-0.3,0.4-0.5c0.2-0.2,0.5-0.2,0.7,0c0,0,0,0,0,0c0.1,0.1,0.1,0.1,0.2,0.2
		c0.2,0.2,0.6,0.2,0.9,0c0.2-0.2,0.5-0.1,0.7,0c0.2,0.2,0.4,0.3,0.5,0.5c-0.3,0.4-0.5,0.7-1,0.6c-0.1,0-0.3,0-0.4,0
		c-0.2,0-0.4-0.1-0.6-0.2c-0.5-0.3-1.1-0.3-1.5,0C-1301.4,763.7-1301.7,763.9-1301.9,764.1z M-1301.1,761.1c0.4-0.2,0.3-0.6,0.3-0.9
		C-1301.1,760.4-1301.2,760.4-1301.1,761.1L-1301.1,761.1z M-1301.3,760.7c-0.2-0.1-0.3,0-0.3,0.2c0,0,0.1,0.1,0.1,0.1
		C-1301.3,761-1301.3,760.9-1301.3,760.7L-1301.3,760.7z"/>
	<path id="Path_185" class="st0" d="M-1312.2,757.4c0.2,2.5,0.2,5.1,0.1,7.6l-12.9,3.2c0.1-0.5,0.1-0.9,0.1-1.4
		c0.3-2.2,0.9-4.4,1.6-6.5c0.1-0.2,0.3-0.4,0.5-0.4c3.4-0.8,6.8-1.7,10.2-2.5C-1312.5,757.4-1312.4,757.4-1312.2,757.4z"/>
	<path id="Path_186" class="st0" d="M-1325.2,770.5l12.9-3.2c0,0.1,0,0.2,0,0.3c-0.1,1.7-0.3,3.3-0.5,5c0,0.4-0.1,0.7-0.1,1.1
		c-0.1,0.7-0.6,1.3-1.4,1.4c-2.9,0.7-5.8,1.4-8.7,2.1c-0.3,0.1-0.6,0.2-0.9,0.2c-0.6,0.2-1.1-0.1-1.3-0.7c0-0.1,0-0.1,0-0.2
		c-0.1-0.3-0.1-0.6-0.1-0.9c0-1.6,0.1-3.3,0.1-4.9C-1325.2,770.7-1325.2,770.6-1325.2,770.5z"/>
	<path id="Path_187" class="st0" d="M-1312.5,755.2l-9.5,2.4c0-0.1,0-0.2,0.1-0.3c1.3-2.6,3-5.1,4.9-7.2c0.4-0.5,1-0.9,1.4-1.4
		c0.1-0.1,0.2-0.2,0.3-0.3c0.4-0.3,0.9-0.3,1.2,0.1c0,0,0.1,0.1,0.1,0.2c0.2,0.2,0.3,0.5,0.4,0.8c0.2,0.7,0.4,1.4,0.5,2.1
		c0.2,1.2,0.3,2.3,0.5,3.5C-1312.5,755-1312.5,755.1-1312.5,755.2z"/>
</g>
</svg>
    </span>
  </ng-container>

  <ng-container *ngSwitchDefault> </ng-container>
</ng-container>
