import { SetWalkingTargetComponent } from './walking/set-walking-target/set-walking-target.component';
import { SearchComponent } from "./search/search.component";
import { AchievementsIndexComponent } from "./achievements/achievements-index/achievements-index.component";
import { EditGoalComponent } from "./goals/edit-goal/edit-goal.component";
import { GoalsAreaComponent } from "./goals/goals-area/goals-area.component";
import { EditYogaWorkoutComponent } from "./yoga/edit-yoga-workout/edit-yoga-workout.component";
import { EditWalkingEntryComponent } from "./walking/edit-walking-entry/edit-walking-entry.component";
import { SymptomEntryComponent } from "./managing/symptom-entry/symptom-entry.component";
import { UpdateSymptomsComponent } from "./managing/update-symptoms/update-symptoms.component";
import { SetNormalSymptomsComponent } from "./managing/set-normal-symptoms/set-normal-symptoms.component";
import { YogaWorkoutsComponent } from "./yoga/yoga-workouts/yoga-workouts.component";
import { AddYogaWorkoutComponent } from "./yoga/add-yoga-workout/add-yoga-workout.component";
import { StrengthWorkoutsComponent } from "./strength/strength-workouts/strength-workouts.component";
import { AddStrengthWorkoutComponent } from "./strength/add-strength-workout/add-strength-workout.component";
import { AddGoalComponent } from "./goals/add-goal/add-goal.component";
import { ManagingIndexComponent } from "./managing/managing-index/managing-index.component";
import { YogaIndexComponent } from "./yoga/yoga-index/yoga-index.component";
import { StrengthIndexComponent } from "./strength/strength-index/strength-index.component";
import { AddWalkingEntryComponent } from "./walking/add-walking-entry/add-walking-entry.component";
import { WalkingWorkoutsComponent } from "./walking/walking-workouts/walking-workouts.component";
import { WalkingIndexComponent } from "./walking/walking-index/walking-index.component";
import { UploadAvatarComponent } from "./auth/upload-avatar/upload-avatar.component";
import { ChangePasswordComponent } from "./auth/change-password/change-password.component";
import { UpdateDetailsComponent } from "./auth/update-details/update-details.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { MoreComponent } from "./more/more.component";
import { RegisterComponent } from "./auth/register/register.component";
import { LoginComponent } from "./auth/login/login.component";
import { HomeComponent } from "./home/home.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { AuthGuard } from "./auth/guard/auth.guard";
import { ForgottenPasswordComponent } from "./auth/forgotten-password/forgotten-password.component";
import { ResetPasswordComponent } from "./auth/reset-password/reset-password.component";
import { OfflineComponent } from "./offline/offline.component";
import { SymptomsComponent } from "./managing/symptoms/symptoms.component";
import { EditStrengthWorkoutComponent } from "./strength/edit-strength-workout/edit-strength-workout.component";
import { ResourcePageComponent } from "./resources/resource-page/resource-page.component";
import { ResourceIndexComponent } from "./resources/resource-index/resource-index.component";
import { SiteContentPageComponent } from "./site-content/site-content-page/site-content-page.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
    data: { hideSiebar: false },
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      animation: "Dashboard",
      hideSiebar: false,
      pageTitle: "Dashboard",
      home: true,
    },
  },
  {
    path: "login",
    component: LoginComponent,
    data: {
      pageTitle: "Login",
      hideNavbar: true,
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      fluidContainer: true,
    },
  },
  {
    path: "register",
    component: RegisterComponent,
    data: {
      subTitle: "Register",
      hideNavbar: true,
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      fluidContainer: true,
    },
  },
  {
    path: "forgotten-password",
    component: ForgottenPasswordComponent,
    data: {
      pageTitle: "Forgotten Password",
      hideNavbar: true,
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      fluidContainer: true,
    },
  },
  {
    path: "reset-password/:id/:hash",
    component: ResetPasswordComponent,
    data: {
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      fluidContainer: true,
      pageTitle: "Reset Password",
    },
  },
  {
    path: "change-password",
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      noContainer: true,
      bgDark: false,
      pageTitle: "Change Password",
      hideTopNav: true,
    },
  },
  {
    path: "update-details",
    component: UpdateDetailsComponent,
    canActivate: [AuthGuard],
    data: { hideSiebar: false, pageTitle: "Account", hideTopNav: true },
  },
  {
    path: "upload-avatar",
    component: UploadAvatarComponent,
    canActivate: [AuthGuard],
    data: { hideSiebar: false, pageTitle: "Account", hideTopNav: true },
  },
  {
    path: "more",
    component: MoreComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "More",
      pageTitle: "More",
      hideTopNav: true,
    },
  },

  {
    path: "more/privacy",
    component: SiteContentPageComponent,
    data: {
      animation: "More",
      pageTitle: "More",
      hideSiebar: false,
      hideTopNav: true,
      pageId: 2,
      moreSection: true,
    },
  },

  {
    path: "more/terms",
    component: SiteContentPageComponent,
    data: {
      animation: "More",
      pageTitle: "More",
      hideSiebar: false,
      hideTopNav: true,
      pageId: 1,
      moreSection: true,
    },
  },

  {
    path: "more/about",
    component: SiteContentPageComponent,
    data: {
      animation: "More",
      pageTitle: "More",
      hideSiebar: false,
      hideTopNav: true,
      pageId: 3,
      moreSection: true,
    },
  },

  {
    path: "more/app",
    component: SiteContentPageComponent,
    data: {
      animation: "More",
      pageTitle: "More",
      hideSiebar: false,
      hideTopNav: true,
      pageId: 4,
      moreSection: true,
    },
  },

  {
    path: "walking",
    component: WalkingIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "WalkingIndex",
      pageTitle: "Walking",
    },
  },

  {
    path: "walking/workouts",
    component: WalkingWorkoutsComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "WalkingWorkouts",
      pageTitle: "Walking",
      hideTopNav: true,
    },
  },

  {
    path: "walking/guide",
    component: ResourceIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "WalkingGuide",
      pageTitle: "Walking",
      hideTopNav: true,
      area: "walking",
    },
  },

  {
    path: "walking/guide/:id",
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "WalkingGuidePage",
      pageTitle: "Walking",
      hideTopNav: true,
      area: "walking",
    },
  },
  {
    path: "walking/set-target",
    component: SetWalkingTargetComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "WalkingSetTarget",
      pageTitle: "Walking",
      hideTopNav: true,
    },
  },
  {
    path: "walking/add-workout",
    component: AddWalkingEntryComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "WalkingAdd",
      pageTitle: "Walking",
      hideTopNav: true,
    },
  },
  {
    path: "walking/edit-workout/:id",
    component: EditWalkingEntryComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "WalkingEdit",
      pageTitle: "Walking",
      hideTopNav: true,
    },
  },
  {
    path: "walking/add-goal",
    component: AddGoalComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "WalkingAddGoal",
      pageTitle: "Walking",
      subTitle: "Add Goal",
      hideTopNav: true,
      area: "walking",
    },
  },
  {
    path: "walking/goals",
    component: GoalsAreaComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "WalkingGoals",
      pageTitle: "Walking",
      subTitle: "Goals",
      hideTopNav: true,
      area: "walking",
    },
  },
  {
    path: "walking/edit-goal/:id",
    component: EditGoalComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "WalkingEditGoal",
      pageTitle: "Walking",
      subTitle: "Add Goal",
      hideTopNav: true,
      area: "walking",
    },
  },
  {
    path: "walking/achievements",
    component: AchievementsIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "WalkingAchievements",
      pageTitle: "Walking",
      subTitle: "Achievements",
      hideTopNav: true,
      area: "walking",
    },
  },

  {
    path: "strength",
    component: StrengthIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "StrengthIndex",
      pageTitle: "Strength",
    },
  },
  {
    path: "strength/add-workout",
    component: AddStrengthWorkoutComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "StrengthAddWorkout",
      pageTitle: "Strength",
      subTitle: "Add Strength Workout",
      hideTopNav: true,
      area: "strength",
    },
  },
  {
    path: "strength/edit-workout/:id",
    component: EditStrengthWorkoutComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "StrengthEditWorkout",
      pageTitle: "Strength",
      subTitle: "Edit Strength Workout",
      hideTopNav: true,
      area: "strength",
    },
  },

  {
    path: "strength/workouts",
    component: StrengthWorkoutsComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "StrengthWorkouts",
      pageTitle: "Strength",
      subTitle: "Strength Workouts",
      hideTopNav: true,
      area: "strength",
    },
  },
  {
    path: "strength/add-goal",
    component: AddGoalComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "StrengthAddGoal",
      pageTitle: "Strength",
      subTitle: "Add Goal",
      hideTopNav: true,
      area: "strength",
    },
  },
  {
    path: "strength/goals",
    component: GoalsAreaComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "StrengthGoal",
      pageTitle: "Strength",
      subTitle: "Goals",
      hideTopNav: true,
      area: "strength",
    },
  },
  {
    path: "strength/edit-goal/:id",
    component: EditGoalComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "StrengthEditGoal",
      pageTitle: "Strength",
      subTitle: "Edit Goal",
      hideTopNav: true,
      area: "strength",
    },
  },
  {
    path: "strength/achievements",
    component: AchievementsIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "StrengthAchievements",
      pageTitle: "Strength",
      subTitle: "Achievements",
      hideTopNav: true,
      area: "strength",
    },
  },

  {
    path: "strength/guide",
    component: ResourceIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "StrengthGuide",
      pageTitle: "Strength",
      hideTopNav: true,
      area: "strength",
    },
  },

  {
    path: "strength/guide/:id",
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "StrengthGuidePage",
      pageTitle: "Strength",
      hideTopNav: true,
      area: "strength",
    },
  },
  {
    path: "yoga",
    component: YogaIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "YogaIndex",
      pageTitle: "Yoga",
    },
  },
  {
    path: "yoga/add-workout",
    component: AddYogaWorkoutComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "YogaAddWorkout",
      pageTitle: "Yoga",
      subTitle: "Add Yoga Workout",
      hideTopNav: true,
      area: "yoga",
    },
  },
  {
    path: "yoga/edit-workout/:id",
    component: EditYogaWorkoutComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "YogaEditWorkout",
      pageTitle: "Yoga",
      subTitle: "Edit Yoga Workout",
      hideTopNav: true,
      area: "yoga",
    },
  },

  {
    path: "yoga/workouts",
    component: YogaWorkoutsComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "YogaWorkouts",
      pageTitle: "Yoga",
      subTitle: "Yoga Workouts",
      hideTopNav: true,
      area: "yoga",
    },
  },
  {
    path: "yoga/add-goal",
    component: AddGoalComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "YogaAddGoal",
      pageTitle: "Yoga",
      subTitle: "Add Goal",
      hideTopNav: true,
      area: "yoga",
    },
  },
  {
    path: "yoga/goals",
    component: GoalsAreaComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "YogaGoals",
      pageTitle: "Yoga",
      subTitle: "Goals",
      hideTopNav: true,
      area: "yoga",
    },
  },
  {
    path: "yoga/edit-goal/:id",
    component: EditGoalComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "YogaEditGoal",
      pageTitle: "Yoga",
      subTitle: "Edit Goal",
      hideTopNav: true,
      area: "yoga",
    },
  },
  {
    path: "yoga/achievements",
    component: AchievementsIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "YogaAchievements",
      pageTitle: "Yoga",
      subTitle: "Achievements",
      hideTopNav: true,
      area: "yoga",
    },
  },

  {
    path: "yoga/guide",
    component: ResourceIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "YogaGuide",
      pageTitle: "Yoga",
      hideTopNav: true,
      area: "yoga",
    },
  },

  {
    path: "yoga/guide/:id",
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "YogaGuidePage",
      pageTitle: "Yoga",
      hideTopNav: true,
      area: "yoga",
    },
  },

  {
    path: "managing",
    component: ManagingIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "ManagingIndex",
      pageTitle: "Managing",
    },
  },

  {
    path: "managing/set-normal-symptoms",
    component: SetNormalSymptomsComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "ManagingNormalSymptoms",
      pageTitle: "Managing",
      subitle: "Set normal symptoms",
      hideTopNav: true,
    },
  },
  {
    path: "managing/update-symptoms",
    component: UpdateSymptomsComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "ManagingUpdateSymptoms",
      pageTitle: "Managing",
      subitle: "Update symptoms",
      hideTopNav: true,
    },
  },
  {
    path: "managing/symptoms",
    component: SymptomsComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "ManagingSymptoms",
      pageTitle: "Managing",
      subitle: "Symptoms",
      hideTopNav: true,
    },
  },
  {
    path: "managing/symptoms/entry/:id",
    component: SymptomEntryComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "ManagingSymptomsEntry",
      pageTitle: "Managing",
      subitle: "Symptoms Entry",
      hideTopNav: true,
    },
  },

  {
    path: "managing/resources",
    component: ResourceIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "ManagingResources",
      pageTitle: "Managing",
      hideTopNav: true,
      area: "managing",
    },
  },

  {
    path: "managing/resources/:id",
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "ManagingResourcesPage",
      pageTitle: "Managing",
      hideTopNav: true,
      area: "managing",
    },
  },
  {
    path: "achievements",
    component: AchievementsIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: "AchievementsIndex",
      pageTitle: "Achievements",
      hideTopNav: true,
    },
  },
  {
    path: "privacy-policy",
    component: SiteContentPageComponent,
    data: {
      hideSiebar: true,
      animation: "Privacy",
      pageTitle: "Privacy Policy",
      hideTopNav: true,
      pageId: 2,
    },
  },
  {
    path: "terms-and-conditions",
    component: SiteContentPageComponent,
    data: {
      animation: "Terms",
      pageTitle: "Terms and Conditions",
      hideSiebar: true,
      hideTopNav: true,
      pageId: 1,
    },
  },
  {
    path: "search",
    component: SearchComponent,
    canActivate: [AuthGuard],
    data: { hideSiebar: false, animation: "Search", pageTitle: "Search" },
  },
  {
    path: "offline",
    component: OfflineComponent,
    data: {
      pageTitle: "Site Offline",
      hideNavbar: true,
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      fluidContainer: true,
    },
  },
  { path: "page-not-found", component: PageNotFoundComponent },
  { path: "**", redirectTo: "/page-not-found" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
