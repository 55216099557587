<div class="fh bg-light content-container">
  <app-page-header
    [title]="'Strength Workouts'"
    [icon]="'strength'"
    [backLink]="['/strength']"
  ></app-page-header>

  <div class="row">
    <div class="col-md-6">
      <div class="card bg-primary mb-3">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon>
            </span>
            Target
          </h2>

          <p class="p-0 m-0">
            Do at least {{ weeklyTarget.days }} strength workouts this week
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card bg-primary-accent mb-3">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'heartbeat']"></fa-icon>
            </span>
            Summary
          </h2>
          <div *ngIf="isLoading" class="text-center">
            <app-loading-spinner [inline]="true"></app-loading-spinner>
          </div>
          <div *ngIf="!isLoading">
            <tabset [justified]="true">
              <tab>
                <ng-template tabHeading> This week </ng-template>
                <div class="entry-days mb-3 mt-3">
                  <div class="entry-day" *ngFor="let day of currentWeekData">
                    <div class="entry-day-label">
                      {{ day.date | date: "EE" | slice: 0:1 }}
                    </div>
                    <div
                      class="entry-day-data na"
                      *ngIf="day.date > today"
                    ></div>
                    <div
                      class="entry-day-data"
                      *ngIf="day.date <= today"
                      [ngClass]="{
                        complete: day.set1_reps != ''
                      }"
                    >
                      <div
                        class="entry-day-data-value"
                        *ngIf="day.set1_reps != ''"
                      >
                        <span class="icon-wrap text-white">
                          <fa-icon [icon]="['fas', 'check']"></fa-icon>
                        </span>
                      </div>
                      <div
                        class="entry-day-data-value"
                        *ngIf="day.set1_reps == '' && day.date !== today"
                      >
                        -
                      </div>

                      <div
                        class="entry-day-data-value relative"
                        *ngIf="day.set1_reps == '' && day.date === today"
                      >
                        <a
                          [routerLink]="['/strength', 'add-workout']"
                          class="stretched-link"
                          ><span class="icon-wrap text-secondary-accent">
                            <fa-icon
                              [icon]="['fas', 'pencil-alt']"
                            ></fa-icon> </span
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="target-status row">
                  <div
                    class="col-6 target-status-status"
                    *ngIf="daysHit < weeklyTarget.days"
                  >
                    <span class="icon-wrap text-primary">
                      <fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon>
                    </span>
                    In Progress
                  </div>

                  <div
                    class="col-6 target-status-status"
                    *ngIf="daysHit >= weeklyTarget.days"
                  >
                    <span class="icon-wrap text-primary">
                      <fa-icon [icon]="['fas', 'check']"></fa-icon>
                    </span>
                    Target Hit!
                  </div>

                  <div class="col-6 text-end target-status-date">
                    {{ currentWeek[0] | date: "EEE d MMM" }} -
                    {{ currentWeek[6] | date: "EEE d MMM" }}
                  </div>
                </div>

                <p class="subtext" *ngIf="daysHit < weeklyTarget.days">
                  You have completed
                  <strong
                    >{{ daysHit }} day<ng-container *ngIf="daysHit != 1"
                      >s</ng-container
                    ></strong
                  >
                  towards your target this week.
                </p>
                <p class="subtext" *ngIf="daysHit < weeklyTarget.days">
                  <strong>{{ weeklyTarget.days - daysHit }} more days</strong>
                  are needed to hit your target.
                </p>
              </tab>
              <tab>
                <ng-template tabHeading> Last 4 weeks </ng-template>

                <div class="status-calendar" *ngIf="last4WeeksData">
                  <div class="status-calendar-row status-calendar-header">
                    <div class="status-calendar-col">M</div>
                    <div class="status-calendar-col">T</div>
                    <div class="status-calendar-col">W</div>
                    <div class="status-calendar-col">T</div>
                    <div class="status-calendar-col">F</div>
                    <div class="status-calendar-col">S</div>
                    <div class="status-calendar-col">S</div>
                    <div class="status-calendar-col">
                      <span class="icon-wrap text-primary">
                        <fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon>
                      </span>
                    </div>
                  </div>

                  <div
                    class="status-calendar-row"
                    *ngFor="let weekNum of [1, 2, 3, 4]"
                  >
                    <div
                      class="status-calendar-col"
                      *ngFor="let day of last4WeeksData[weekNum].days"
                    >
                      <span
                        class="status-calendar-day"
                        [ngClass]="{
                          complete: day.set1_reps != '',
                          incomplete: day.set1_reps == '' && day.date < today
                        }"
                        >{{ day.date | date: "dd" }}</span
                      >
                    </div>
                    <div class="status-calendar-col">
                      <span
                        class="icon-wrap text-primary"
                        *ngIf="
                          +last4WeeksData[weekNum].daysHit < +weeklyTarget.days
                        "
                      >
                        <fa-icon [icon]="['fas', 'times']"></fa-icon>
                      </span>
                      <span
                        class="icon-wrap text-secondary-accent"
                        *ngIf="
                          +last4WeeksData[weekNum].daysHit >= +weeklyTarget.days
                        "
                      >
                        <fa-icon [icon]="['fas', 'check']"></fa-icon>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="last4WeeksData">
                  <div class="col">
                    <p class="subtext">
                      {{ last4WeeksData?.total.daysActive }} active days
                    </p>
                  </div>
                  <div class="col text-end">
                    <p class="subtext">
                      {{ last4WeeksData[1].days[0].date | date: "EEE d MMM" }} -
                      {{ last4WeeksData[4].days[6].date | date: "EEE d MMM" }}
                    </p>
                  </div>
                </div>
              </tab>
              <tab>
                <ng-template tabHeading> All time </ng-template>

                <div class="row">
                  <div class="col-6 mb-3">
                    <div class="status-badge">
                      <div class="status-badge-icon">
                        <span class="icon-wrap">
                          <fa-icon [icon]="['fas', 'heartbeat']"></fa-icon>
                        </span>
                      </div>
                      <div class="status-badge-text">
                        <div class="status-badge-title">Total Workouts</div>
                        <div class="status-badge-status">
                          {{ stats?.total }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 mb-3">
                    <div class="status-badge">
                      <div class="status-badge-icon">
                        <span class="icon-wrap">
                          <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
                        </span>
                      </div>
                      <div class="status-badge-text">
                        <div class="status-badge-title">Active Days</div>
                        <div class="status-badge-status">
                          {{ stats?.activeDays }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 mb-3">
                    <div class="status-badge">
                      <div class="status-badge-icon">
                        <span class="icon-wrap">
                          <fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon>
                        </span>
                      </div>
                      <div class="status-badge-text">
                        <div class="status-badge-title">Target achieved</div>
                        <div class="status-badge-status">
                          {{ stats?.targetHit }}<span> times</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 mb-3">
                    <div class="status-badge">
                      <div class="status-badge-icon">
                        <span class="icon-wrap">
                          <fa-icon [icon]="['fas', 'arrows-h']"></fa-icon>
                        </span>
                      </div>
                      <div class="status-badge-text">
                        <div class="status-badge-title">Longest streak</div>
                        <div class="status-badge-status">
                          {{ stats?.streak }}<span> days</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </tab>
            </tabset>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button
        class="btn btn-secondary rounded-pill w-100"
        [routerLink]="['/strength', 'add-workout']"
      >
        Add a Strength Workout
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <h2 class="inline-title">
        <span class="float-end muted"
          >{{ allWorkouts?.length }} workout<span
            *ngIf="allWorkouts?.length != 1"
            >s</span
          ></span
        >All Workouts
      </h2>
      <div *ngIf="isLoading" class="text-center">
        <app-loading-spinner [inline]="true"></app-loading-spinner>
      </div>
      <div *ngIf="!isLoading">
        <div *ngFor="let month of workoutMonths">
          <h3 class="month-header">{{ month | slice: 0:-2 }}</h3>
          <ul class="task-list task-list-todo task-list-bt">
            <ng-container *ngFor="let workout of allWorkouts">
              <li *ngIf="(workout.workout_date | date: 'MMMMYY') == month">
                <span class="task">
                  <span class="date">
                    {{ workout.workout_date | date: "EEE d" }}
                  </span>
                  <span class="title"
                    >Set1 : {{ workout.set1_reps }} reps<br />
                    Set 2 : {{ workout.set2_reps }} reps<br />
                    Set 3 : {{ workout.set3_reps }} reps</span
                  >
                  <span class="title">
                    <app-difficulty-icon
                      [difficulty]="+workout.difficulty"
                    ></app-difficulty-icon
                  ></span>
                  <span class="status">
                    <span class="icon-wrap text-secondary-accent" dropdown>
                      <button
                        dropdownToggle
                        type="button"
                        class="btn"
                        aria-controls="dropdown-basic"
                      >
                        <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                      </button>
                      <ul
                        *dropdownMenu
                        class="dropdown-menu dropdown-menu-right"
                        role="menu"
                        aria-labelledby="button-basic"
                      >
                        <li role="menuitem">
                          <a
                            class="dropdown-item"
                            [routerLink]="[
                              '/strength',
                              'edit-workout',
                              workout.user_strength_workout_id
                            ]"
                            >Edit</a
                          >
                        </li>
                        <li role="menuitem">
                          <a
                            class="dropdown-item"
                            (click)="
                              onDeleteEntry(workout.user_strength_workout_id)
                            "
                            >Delete</a
                          >
                        </li>
                      </ul>
                    </span>
                  </span>
                </span>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
