import { forkJoin } from "rxjs";
import { UserSymptom } from "./../../models/user-symptom.model";
import { Router, ActivatedRoute } from "@angular/router";
import { SymptomService } from "./../../services/symptom.service";
import { NgForm } from "@angular/forms";
import { Component, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2/src/sweetalert2.js";

@Component({
  selector: "app-set-normal-symptoms",
  templateUrl: "./set-normal-symptoms.component.html",
  styleUrls: ["./set-normal-symptoms.component.scss"],
})
export class SetNormalSymptomsComponent implements OnInit {
  @ViewChild("form", { static: false }) entryForm?: NgForm;
  submitted = false;
  error?: string;
  area = "walking";
  isLoading = false;
  public normalSymptoms!: UserSymptom | undefined;
  public errors: any = [];

  constructor(
    private symptomService: SymptomService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    if (this.route.snapshot.data["area"]) {
      this.area = this.route.snapshot.data["area"];
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    forkJoin({
      normalSymptoms: this.symptomService.fetchNormal(),
    }).subscribe(
      (responseData) => {
        this.isLoading = false;
        this.normalSymptoms = responseData.normalSymptoms;
      },
      (error) => {
        this.errors.push(error.message);
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    if (!this.entryForm?.valid) {
      Swal.fire({
        icon: "error",
        title: "The form is invalid",
        text: "Please check the form for errors",
        showConfirmButton: true,
      });
    } else {
      // create entry
      const resourceIds = [];
      this.symptomService
        .setNormal(
          this.entryForm?.value.breathlessness_rest,
          this.entryForm?.value.breathlessness_active,
          this.entryForm?.value.sputum,
          this.entryForm?.value.sputum_colour,
          this.entryForm?.value.wheeze,
          this.entryForm?.value.fever
        )
        .subscribe(
          (responseData) => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Normal symptoms set",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              toast: true,
            });
            this.router.navigate(["/managing"]);
            this.entryForm?.reset();
          },
          (error) => {
            this.error = error.message;
            console.log(this.error);
      this.entryForm?.reset();
          }
        );
    }
  }
}
