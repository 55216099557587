<div class="fh bg-light pb-10 content-container">
  <app-page-header
  [title]="'Walking Guide'"
  [icon]="'walking'"
  [backLink]="['/walking']"
  *ngIf="area =='walking'"
></app-page-header>

<app-page-header
[title]="'Strength Guide'"
[icon]="'strength'"
[backLink]="['/strength']"
*ngIf="area =='strength'"
></app-page-header>

<app-page-header
[title]="'Yoga Guide'"
[icon]="'yoga'"
[backLink]="['/yoga']"
*ngIf="area =='yoga'"
></app-page-header>

<app-page-header
[title]="'Managing Resources'"
[icon]="'symptoms'"
[backLink]="['/managing']"
*ngIf="area =='managing'"
></app-page-header>

  <div class="container relative">
    <app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
    <div class="container">
    
      <img
      *ngIf="resource?.image_filename"
      src="{{ resource.image_filename | getThumbnail: 'wide' }}"
      alt="Placeholder image"
      class="rounded-lg img-fluid fade-in d-none d-sm-block"
    />

    <img
      *ngIf="resource?.image_filename"
      src="{{ resource.image_filename | getThumbnail: 'medium-landscape' }}"
      alt="Placeholder image"
      class="rounded-lg img-fluid fade-in d-block d-sm-none"
    />
      <ul class="task-list task-list-todo task-list-bt">
        <li *ngFor="let page of pages">
          <a
            class="task"
            [routerLink]="[
              '/walking',
              'guide',
              page.content_page_id
            ]"
          >
            <span class="num">
              <span class="icon-wrap">
                <fa-icon
                  *ngIf="!page.icon"
                  [icon]="['fas', 'align-left']"
                  [fixedWidth]="true"
                ></fa-icon>
                <fa-icon
                  *ngIf="page.icon"
                  [icon]="['far', page.icon]"
                  [fixedWidth]="true"
                ></fa-icon>
              </span>
            </span>
            <span class="title">{{ page.title }}</span>
            <span class="status">
              <span class="icon-wrap text-secondary-accent">
                <fa-icon [icon]="['fas', 'arrow-alt-right']"></fa-icon>
              </span>
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
