<div class="fh bg-light pb-10 content-container">
    <app-page-header
      [title]="'Account'"
      [icon]="''"
      [backLink]="['/dashboard']"
    ></app-page-header>

    <div class="row">
      <div class="col-md-12">

        <h2 class="sm-title">Upload a new image</h2>          
        
        <form action="" class="standard-form">
            <div class="form-group">
        <div class="custom-file">
            <input type="file" name="image" (change)="fileProgress($event)" id="customFile" class="custom-file-input" />
            <label class="custom-file-label visually-hidden" for="customFile">Please select an image</label>
          </div>
        <div *ngIf="fileUploadProgress">
            Upload progress: {{ fileUploadProgress }}
        </div>
        <div class="image-preview mb-3" *ngIf="previewUrl">
            <img [src]="previewUrl" height="300" />                 
        </div>
            </div>
        <div class="mb-3" *ngIf="uploadedFilePath">
            {{uploadedFilePath}}
        </div>
         
        <div class="form-group">
            <button class="btn btn-primary"  (click)="onSubmit()">Submit</button>
        </div>
    </form>
      </div>
    </div>
  </div>
