<div class="fh bg-light pb-10 content-container">
  <app-page-header
    [title]="'Account'"
    [icon]="''"
    [backLink]="['/dashboard']"
  ></app-page-header>
  <div class="row" *ngIf="userData">
    <div class="col-md-12">
      <h3 class="mb-3 sm-title">Update your details</h3>
      <div class="alert alert-danger" *ngIf="error">
        <p>{{ error }}</p>
      </div>
      <div *ngIf="isLoading" class="text-center">
        <app-loading-spinner></app-loading-spinner>
      </div>
      <form
        #authForm="ngForm"
        (ngSubmit)="onSubmit(authForm)"
        *ngIf="!updated"
        class="standard-form"
        name="update-details"
      >
        <div class="form-group">
          <label for="firstName">First Name</label>
          <input
            type="text"
            id="firstName"
            class="form-control"
            [ngModel]="userData.first_name"
            name="firstName"
            placeholder="First Name"
            required
          />
        </div>
        <div class="form-group">
          <label for="lastName">Last Name</label>
          <input
            type="text"
            id="lastName"
            class="form-control"
            [ngModel]="userData.last_name"
            name="lastName"
            placeholder="Last Name"
            required
          />
        </div>

        <!--div class="form-group">
            <label for="screenName">Screen Name</label>
              
              <input
                type="text"
                id="screenName"
                class="form-control"
                [ngModel]="userData.screen_name"
                name="screenName"
                placeholder="Screen Name"
                required
                pattern="[a-zA-Z0-9]*"
                #screenNameInput="ngModel"

              [ngClass]="{ 'is-invalid': screenNameInput.touched && !screenNameInput.valid }"
              />
              <span *ngIf="!screenNameInput.valid">Please use numbers letters only</span>
          </div-->
        <div class="form-group">
          <label for="email">Email</label>
          <input
            type="email"
            id="email"
            class="form-control"
            [ngModel]="userData.email"
            name="email"
            placeholder="Email"
            required
            email
          />
        </div>


        <div class="form-group">
          <label for="language">Language</label>
          <select
            id="language"
            class="form-select"
            [ngModel]="userData.language_id"
            name="language"
            placeholder="language"
            required
          >
          <option [value]="undefined">Select a Language</option>
          <option [value]="'1'">English</option>
          </select>
        </div>

        <div class="form-group">
        <button
          type="submit"
          [disabled]="!authForm.valid || isLoading"
          class="btn btn-primary btn-block"
        >
          Update
        </button>
        </div>
      </form>
      <p *ngIf="updated">
        If your email exists you will have been sent a link to reset your
        password.
      </p>
      <hr />
    </div>
  </div>
</div>
