<div class="add-to-homescreen" *ngIf="showInstallMessage" [ngClass]="{'iphone' : device ==='iphone' || device ==='ipod', 'ipad' : device ==='ipad', 'android': device ==='android'}"> 
  <div class="masthead">
      Get a better experience on your <span *ngIf="device ==='iphone' ">iPhone</span><span *ngIf="device ==='ipod' ">iPod</span><span *ngIf="device ==='ipad' ">iPad</span> <span *ngIf="device === 'android'">device</span> by running {{environment.projectName}} as an
      app.
      <div class="strapline mt-3" *ngIf="device !=='android'">No App Store required.</div>
      <div class="strapline mt-3" *ngIf="device ==='android'">No Google Play Store required.</div>
      
    </div>
    <div class="background-container">
      <div class="instructions">
        <div class="instructions-content">
  
          <div *ngIf="device==='android'">
            
            <div class="text-center">
              <div *ngIf="canInstallAndroid">
                Simply click the button below and accept any prompts afterwards
                <button (click)="onInstallAndroid($event)" class="btn btn-white btn-wide mt-3 mb-3">
                  Install now
                </button>
              </div>
              <div *ngIf="!canInstallAndroid">
                <ol>
                  <li>In the Chrome app tap the menu icon (3 dots in upper right-hand corner) and then tap <strong>Add to homescreen</strong>.</li>
                    <li>You’ll then be able to enter a name for the shortcut and then Chrome will add it to your home screen.</li>
                </ol>
              </div>
             
              <hr />
          <div class="subtext">
            This will create a <strong>{{environment.pwaName}} </strong> app icon so you can launch {{environment.projectName}} like you would any other app on your device.
          </div>
  
          <div class="text-center mt-3 mb-3">
            <button (click)="onSkip($event)" class="btn btn-outline-white btn-wide">
              Skip for now
            </button>
          </div>
            </div>
          </div>
          <div *ngIf="device!=='android'">
          Two easy steps to install:
          <ol>
            <li>In Safari simply press the <img src="/assets/img/ios-share.png" alt="IOS share button" width="25" class="share-icon"> button.</li>
            <li>Find and press the <strong>Add to Home Screen</strong> link from the menu.</li>
          </ol>
          <hr />
          <div class="subtext">
            This will create a <strong>{{environment.pwaName}}</strong> app icon so you can launch {{environment.projectName}} like you would any other app on your device.
          </div>
  
          <div class="text-center mt-3 mb-3">
            <button (click)="onSkip($event)" class="btn btn-light btn-wide">
              Skip for now
            </button>
          </div>
  
            
        </div>
        </div>
      </div>
    </div>
  </div>
  