<div class="fh bg-light pb-10 content-container">
  <app-page-header
    *ngIf="area === 'walking'"
    [title]="'Update Walking Goal'"
    [icon]="'walking'"
    [backLink]="['/walking']"
  ></app-page-header>

  <app-page-header
    *ngIf="area === 'strength'"
    [title]="'Update Strength Goal'"
    [icon]="'strength'"
    [backLink]="['/strength']"
  ></app-page-header>

  <app-page-header
    *ngIf="area === 'yoga'"
    [title]="'Update Yoga Goal'"
    [icon]="'yoga'"
    [backLink]="['/yoga']"
  ></app-page-header>

  <div class="row">
    <div class="col">
      <form class="form-recharge" (ngSubmit)="onSubmit()" #form="ngForm">
        <div class="form-group">
          <label for="duration text-center">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'star']"></fa-icon>
            </span>
            YOUR GOAL</label
          >
          <div class="col-12">
            <textarea
              id="goal"
              class="form-control"
              [ngModel]="userGoal?.goal"
              #goal="ngModel"
              name="goal"
              placeholder="Enter your text"
              required
              rows="6"
            ></textarea>
            <div
          class="alert alert-danger"
          *ngIf="!goal.valid && submitted"
            >
              Please enter a goal above
          </div>
          </div>
        </div>


        <div class="form-group">
            <label for="duration text-center">
              <span class="icon-wrap text-secondary-accent">
                <fa-icon [icon]="['fas', 'check']"></fa-icon>
              </span>
              Achieved?</label
            >
            <div class="col-sm-6 mb-2">
                <div class="radio-group radio-group-bool">
                  <div class="form-check form-check-inline">
                    <input
                      [ngModel]="userGoal?.status"
                      #set1_reps="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="status"
                      id="status1"
                      [value]="1"
                    />
                    <label class="form-check-label" for="status1">
                        <fa-icon [icon]="['fas', 'check']"></fa-icon>
                    </label>
                  </div>
    
                  <div class="form-check form-check-inline">
                    <input
                      [ngModel]="userGoal?.status"
                      #set1_reps="ngModel"
                      class="form-check-input"
                      type="radio"
                      required
                      name="status"
                      id="status2"
                      [value]="0"
                    />
                    <label class="form-check-label" for="status2"
                      ><fa-icon [icon]="['fas', 'times']"></fa-icon></label
                    >
                  </div>
                </div>
              </div>
          </div>

        <button class="btn btn-secondary rounded-pill w-100">
          Update Goal
        </button>
      </form>
    </div>
  </div>
</div>
