<div class="fh bg-primary text-white pb-10 content-container">
  <app-page-header
    *ngIf="area === 'global'"
    [title]="'Achievements'"
    [icon]="'achievement'"
    [backLink]="['/dashboard']"
  ></app-page-header>

  <app-page-header
    *ngIf="area === 'walking'"
    [title]="'Achievements'"
    [icon]="'achievement'"
    [backLink]="['/walking']"
  ></app-page-header>

  <app-page-header
    *ngIf="area === 'strength'"
    [title]="'Achievements'"
    [icon]="'achievement'"
    [backLink]="['/strength']"
  ></app-page-header>

  <app-page-header
    *ngIf="area === 'yoga'"
    [title]="'Achievements'"
    [icon]="'achievement'"
    [backLink]="['/yoga']"
  ></app-page-header>

  <div class="row">
    <div class="col">
      <div class="card bg-white">
        <div class="card-body">
          <h2 class="card-title text-start card-title-sm">
            Weekly Participation Streak
          </h2>
          <div *ngIf="isLoading" class="text-center">
            <app-loading-spinner [inline]="true"></app-loading-spinner>
          </div>

          <div *ngIf="!isLoading">
            <div *ngIf="weeklyParticipationBadges.length === 0">
              <p>No badges yet - keep going!</p>
            </div>

            <div
              *ngIf="weeklyParticipationBadges.length > 0"
              class="achievements"
            >
              <app-achievement-badge
                *ngFor="let achievement of weeklyParticipationBadges"
                [achievement]="achievement"
                [popover]="getPopoverContent(achievement)"
                popoverTitle="{{achievement.total}}-Week Streak Badge"
                placement="top"
                [outsideClick]="true"
              ></app-achievement-badge>
            </div>

            <p class="card-text-sm">
              <strong>Next streak:</strong>
              {{ nextParticipationStreak }} weeks
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <tabset [justified]="true">
        <tab [active]="area ==='global' || area === 'walking'">
          <ng-template tabHeading> Walking </ng-template>

          <div class="card bg-primary-accent mt-3">
            <div class="card-body">
              <h2 class="card-title text-start card-title-sm">
                Workout Milestones
              </h2>

              <div *ngIf="isLoading" class="text-center">
                <app-loading-spinner [inline]="true"></app-loading-spinner>
              </div>

              <div *ngIf="!isLoading">
                <div *ngIf="walkingWorkoutMilestones.length === 0">
                  <p>No badges yet - keep going!</p>
                </div>

                <div
                  *ngIf="walkingWorkoutMilestones.length > 0"
                  class="achievements achievements-left mt-3 mb-3"
                >
                  <app-achievement-badge
                    *ngFor="let achievement of walkingWorkoutMilestones"
                    [achievement]="achievement"
                  ></app-achievement-badge>
                </div>

                <p class="card-text-sm">
                  <strong>Next milestone:</strong>
                  {{ nextWalkingWorkoutMilestone }} workouts<br />
                  <strong>Current Total:</strong>
                  {{ current.walkingWorkouts }} workouts
                </p>
              </div>
            </div>
          </div>

          <div class="card bg-primary-accent mt-3">
            <div class="card-body">
              <h2 class="card-title text-start card-title-sm">
                Goals Completed
              </h2>

              <div *ngIf="isLoading" class="text-center">
                <app-loading-spinner [inline]="true"></app-loading-spinner>
              </div>

              <div *ngIf="!isLoading">
                <div *ngIf="walkingGoals.length === 0">
                  <p>No badges yet - keep going!</p>
                </div>

                <div
                  *ngIf="walkingGoals.length > 0"
                  class="achievements achievements-left mt-3 mb-3"
                >
                  <app-achievement-badge
                    *ngFor="let achievement of walkingGoals"
                    [achievement]="achievement"
                  ></app-achievement-badge>
                </div>

                <p class="card-text-sm">
                  <strong>Next award:</strong>
                  {{ nextWalkingGoalAward }} goals<br />
                  <strong>Current Total:</strong>
                  {{ current.walkingGoals }} goals
                </p>
              </div>
            </div>
          </div>

          <div class="card bg-primary-accent mt-3">
            <div class="card-body">
              <h2 class="card-title text-start card-title-sm">Daily Streaks</h2>

              <div *ngIf="isLoading" class="text-center">
                <app-loading-spinner [inline]="true"></app-loading-spinner>
              </div>

              <div *ngIf="!isLoading">
                <div *ngIf="walkingStreaks.length === 0">
                  <p>No badges yet - keep going!</p>
                </div>

                <div
                  *ngIf="walkingStreaks.length > 0"
                  class="achievements achievements-left mt-3 mb-3"
                >
                  <app-achievement-badge
                    *ngFor="let achievement of walkingStreaks"
                    [achievement]="achievement"
                    [popover]="getPopoverContent(achievement)"
                    popoverTitle="{{achievement.total}}-Day Streak Badge"
                    placement="top"
                    [outsideClick]="true"
                  ></app-achievement-badge>
                </div>

                <p class="card-text-sm">
                  <strong>Next streak achievement:</strong>
                  {{ nextWalkingStreak }}<br />
                  <strong>Current streak:</strong>
                  {{ current.walkingStreak }} day<span *ngIf="current.walkingStreak!=1">s</span>
                </p>
              </div>
            </div>
          </div>
        </tab>
        <tab [active]="area ==='strength'">
          <ng-template tabHeading> Strength </ng-template>

          <div class="card bg-primary-accent mt-3">
            <div class="card-body">
              <h2 class="card-title text-start card-title-sm">
                Workout Milestones
              </h2>

              <div *ngIf="isLoading" class="text-center">
                <app-loading-spinner [inline]="true"></app-loading-spinner>
              </div>

              <div *ngIf="!isLoading">
                <div *ngIf="strengthWorkoutMilestones.length === 0">
                  <p>No badges yet - keep going!</p>
                </div>

                <div
                  *ngIf="strengthWorkoutMilestones.length > 0"
                  class="achievements achievements-left mt-3 mb-3"
                >
                  <app-achievement-badge
                    *ngFor="let achievement of strengthWorkoutMilestones"
                    [achievement]="achievement"
                  ></app-achievement-badge>
                </div>

                <p class="card-text-sm">
                  <strong>Next milestone:</strong>
                  {{ nextStrengthWorkoutMilestone }} workouts<br />
                  <strong>Current Total:</strong>
                  {{ current.strengthWorkouts }} workouts
                </p>
              </div>
            </div>
          </div>

          <div class="card bg-primary-accent mt-3">
            <div class="card-body">
              <h2 class="card-title text-start card-title-sm">
                Goals Completed
              </h2>

              <div *ngIf="isLoading" class="text-center">
                <app-loading-spinner [inline]="true"></app-loading-spinner>
              </div>

              <div *ngIf="!isLoading">
                <div *ngIf="strengthGoals.length === 0">
                  <p>No badges yet - keep going!</p>
                </div>

                <div
                  *ngIf="strengthGoals.length > 0"
                  class="achievements achievements-left mt-3 mb-3"
                >
                  <app-achievement-badge
                    *ngFor="let achievement of strengthGoals"
                    [achievement]="achievement"
                  ></app-achievement-badge>
                </div>

                <p class="card-text-sm">
                  <strong>Next award:</strong>
                  {{ nextStrengthGoalAward }} goals<br />
                  <strong>Current Total:</strong>
                  {{ current.strengthGoals }} goals
                </p>
              </div>
            </div>
          </div>

          <div class="card bg-primary-accent mt-3">
            <div class="card-body">
              <h2 class="card-title text-start card-title-sm">Daily Streaks</h2>

              <div *ngIf="isLoading" class="text-center">
                <app-loading-spinner [inline]="true"></app-loading-spinner>
              </div>

              <div *ngIf="!isLoading">
                <div *ngIf="strengthStreaks.length === 0">
                  <p>No badges yet - keep going!</p>
                </div>

                <div
                  *ngIf="strengthStreaks.length > 0"
                  class="achievements achievements-left mt-3 mb-3"
                >
                  <app-achievement-badge
                    *ngFor="let achievement of strengthStreaks"
                    [achievement]="achievement"
                    [popover]="getPopoverContent(achievement)"
                    popoverTitle="{{achievement.total}}-Day Streak Badge"
                    placement="top"
                    [outsideClick]="true"
                  ></app-achievement-badge>
                </div>

                <p class="card-text-sm">
                  <strong>Next streak achievement:</strong>
                  {{ nextStrengthStreak }}<br />
                  <strong>Current streak:</strong>
                  {{ current.strengthStreak }} days
                </p>
              </div>
            </div>
          </div>
        </tab>
        <tab [active]="area ==='yoga'">
          <ng-template tabHeading> Yoga </ng-template>

          <div class="card bg-primary-accent mt-3">
            <div class="card-body">
              <h2 class="card-title text-start card-title-sm">
                Workout Milestones
              </h2>

              <div *ngIf="isLoading" class="text-center">
                <app-loading-spinner [inline]="true"></app-loading-spinner>
              </div>

              <div *ngIf="!isLoading">
                <div *ngIf="yogaWorkoutMilestones.length === 0">
                  <p>No badges yet - keep going!</p>
                </div>

                <div
                  *ngIf="yogaWorkoutMilestones.length > 0"
                  class="achievements achievements-left mt-3 mb-3"
                >
                  <app-achievement-badge
                    *ngFor="let achievement of yogaWorkoutMilestones"
                    [achievement]="achievement"
                  ></app-achievement-badge>
                </div>

                <p class="card-text-sm">
                  <strong>Next milestone:</strong>
                  {{ nextYogaWorkoutMilestone }} workouts<br />
                  <strong>Current Total:</strong>
                  {{ current.yogaWorkouts }} workouts
                </p>
              </div>
            </div>
          </div>

          <div class="card bg-primary-accent mt-3">
            <div class="card-body">
              <h2 class="card-title text-start card-title-sm">
                Goals Completed
              </h2>

              <div *ngIf="isLoading" class="text-center">
                <app-loading-spinner [inline]="true"></app-loading-spinner>
              </div>

              <div *ngIf="!isLoading">
                <div *ngIf="yogaGoals.length === 0">
                  <p>No badges yet - keep going!</p>
                </div>

                <div
                  *ngIf="yogaGoals.length > 0"
                  class="achievements achievements-left mt-3 mb-3"
                >
                  <app-achievement-badge
                    *ngFor="let achievement of yogaGoals"
                    [achievement]="achievement"
                  ></app-achievement-badge>
                </div>

                <p class="card-text-sm">
                  <strong>Next award:</strong>
                  {{ nextYogaGoalAward }} goals<br />
                  <strong>Current Total:</strong>
                  {{ current.yogaGoals }} goals
                </p>
              </div>
            </div>
          </div>

          <div class="card bg-primary-accent mt-3">
            <div class="card-body">
              <h2 class="card-title text-start card-title-sm">Daily Streaks</h2>

              <div *ngIf="isLoading" class="text-center">
                <app-loading-spinner [inline]="true"></app-loading-spinner>
              </div>

              <div *ngIf="!isLoading">
                <div *ngIf="yogaStreaks.length === 0">
                  <p>No badges yet - keep going!</p>
                </div>

                <div
                  *ngIf="yogaStreaks.length > 0"
                  class="achievements achievements-left mt-3 mb-3"
                >
                  <app-achievement-badge
                    *ngFor="let achievement of yogaStreaks"
                    [achievement]="achievement"
                    [popover]="getPopoverContent(achievement)"
                    popoverTitle="{{achievement.total}}-Day Streak Badge"
                    placement="top"
                    [outsideClick]="true"
                  ></app-achievement-badge>
                </div>

                <p class="card-text-sm">
                  <strong>Next streak achievement:</strong>
                  {{ nextYogaStreak }}<br />
                  <strong>Current streak:</strong>
                  {{ current.yogaStreak }} days
                </p>
              </div>
            </div>
          </div>
        </tab>
      </tabset>
    </div>
  </div>
</div>
