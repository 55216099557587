import { UserTargetService } from "./../../services/user-target.service";
import { UserTarget } from "./../../models/user-target.model";
import { UserWeeklyTarget } from "./../../models/user-weekly-target.model";
import { WeeklyTargetService } from "./../../services/weekly-target.service";
import { Component, OnInit } from "@angular/core";
import { forkJoin } from "rxjs";
import { UserService } from "src/app/auth/service/user.service";
import { UserWalking } from "src/app/models/user-walking.model";
import { DateHelperService } from "src/app/services/date-helper.service";
import { WalkingService } from "src/app/services/walking.service";
import Swal from "sweetalert2/src/sweetalert2.js";

@Component({
  selector: "app-walking-workouts",
  templateUrl: "./walking-workouts.component.html",
  styleUrls: ["./walking-workouts.component.scss"],
})
export class WalkingWorkoutsComponent implements OnInit {
  public isLoading = false;
  // public userWalks!: UserWalking[];
  public allWalks!: UserWalking[];
  public allWeeklyTargets!: UserWeeklyTarget[];
  public daysHit = 0;
  public weeklyTarget = this.walkingService.weeklyTarget;
  public errors: any = [];
  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public currentWeekData!: any[];
  public last4Weeks: Date[] = this.dateHelperService.getLast4tWeeks();
  public last4WeeksData!: any;
  public stats: any;
  public monthsWalked: string[] = [];
  public allTargets: UserTarget[] = [];
  public currentTarget?: UserTarget;

  public today = this.dateHelperService.getToday();

  constructor(
    private walkingService: WalkingService,
    private dateHelperService: DateHelperService,
    private weeklyTargetService: WeeklyTargetService,
    private userTargetService: UserTargetService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    const startDate = this.currentWeek[0];
    const endDate = this.currentWeek[6];
    forkJoin({
      allWalks: this.walkingService.fetchAll(),
      allTargets: this.weeklyTargetService.fetchAll(),
      targets: this.userTargetService.fetchAll(),
    }).subscribe(
      (responseData) => {
        this.isLoading = false;

        this.allTargets = responseData.targets;
        this.currentTarget = this.userTargetService.getLatestTarget(
          this.allTargets,
          "walking"
        );
        if (this.currentTarget) {
          this.walkingService.weeklyTarget.threshold =
            +this.currentTarget.target;
        }

        this.allWalks = responseData.allWalks;
        this.currentWeekData = this.walkingService.generateWeekArray(
          this.currentWeek,
          this.allWalks
        );
        this.last4WeeksData = this.walkingService.generateLast4WeeksArray(
          this.last4Weeks,
          this.allWalks
        );

        this.daysHit = this.walkingService.getDaysHit(this.currentWeekData);
        this.stats = this.walkingService.getStats(
          this.allWalks,
          this.allWeeklyTargets
        );

        this.monthsWalked = this.walkingService.getMonths(this.allWalks);
      },
      (error) => {
        this.errors.push(error.message);
      }
    );
  }

  onDeleteEntry(entryId: number) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to undo this",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it",
    }).then((result) => {
      if (result.value) {
        this.walkingService.delete(entryId).subscribe(
          (responseData) => {
            Swal.fire("Deleted", "Your entry has been deleted.", "success");
            // remove it from the walks..

            let deleteIndex = -1;
            this.allWalks.forEach((walk, walkIndex) => {
              if (+walk.user_walking_id === +entryId) {
                deleteIndex = walkIndex;
              }
            });

            if (deleteIndex != -1) {
              // splice it
              this.allWalks.splice(deleteIndex, 1);
            }
          },
          (error) => {
            this.isLoading = false;
            this.errors.push(error.message);
            Swal.fire("Deleted!", "There was an error.", "error");
          }
        );
      }
    });
  }
}
