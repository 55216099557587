import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map, catchError, tap, publishReplay, refCount } from "rxjs/operators";
import { Observable, Subject, throwError } from "rxjs";

import { environment } from "./../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ResourcesService {
  resourceIdMap: any = {
    walking: 1,
    strength: 4,
    yoga: 5,
    managing: 6,
    patientStories: 7,
  };
  cachedResource: any;
  cacheTime = new Date();
  cacheLifetime = 1 * 60 * 1000;

  constructor(private http: HttpClient) {}

  fetchAll() {
    return this.http
      .get<any>(environment.apiUrl + "/resources", {
        responseType: "json",
      })
      .pipe(
        map((responseData) => {
          const resourcesArray: any = [];
          responseData["_embedded"].resources.forEach((resource: any) => {
            resourcesArray.push(resource);
          });
          return resourcesArray;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchResource(resourceId: number): Observable<any> {
    if (!this.cachedResource) {
      this.cachedResource = {};
    }
    const now = new Date();
    if (
      !this.cachedResource[resourceId] ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      this.cacheTime = new Date();
      this.cachedResource[resourceId] = this.http
        .get<any>(environment.apiUrl + "/resources/" + resourceId, {
          responseType: "json",
        })
        .pipe(
          map((responseData) => {
            responseData.pages.forEach((page: any, index: number) => {
              if (responseData.pages[index].content) {
                switch (+responseData.pages[index].page_type_id) {
                  // default
                  case 1:
                  default:
                    responseData.pages[index].content = this.parseContent(
                      responseData.pages[index].content
                    );
                    break;
                  // video
                  case 2:
                    const contentParts = JSON.parse(
                      responseData.pages[index].content
                    );
                    contentParts.videoUrl = this.parseContent(
                      contentParts.videoUrl
                    );
                    contentParts.posterUrl = this.parseContent(
                      contentParts.posterUrl
                    );
                    contentParts.captionsUrl = this.parseContent(
                      contentParts.captionsUrl
                    );
                    contentParts.content = this.parseContent(
                      contentParts.content
                    );
                    responseData.pages[index].content = contentParts;
                    break;
                }
              }
            });

            return responseData;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.cachedResource[resourceId];
  }

  parseContent(content: string) {
    // disable video download button
    content = content
      .split("<video ")
      .join('<video controlsList="nodownload" ');
    // update asset refs
    return content.split("/assets/").join(environment.staticUrl + "/");
    //    return content.replace('/\/assets\//g', environment.staticUrl + '/');
  }

  clearCache() {
    this.cachedResource = null;
  }
}
