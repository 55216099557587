<div *ngIf="isLoading" class="text-center">
  <app-loading-spinner></app-loading-spinner>
</div>
<div *ngIf="!isLoading">
  <div class="container-fluid content-container fh login">
    <img src="/assets/img/logo-recharge-white.svg" alt="RECHARGE logo" class="logo" width="200">
    <h2>Login</h2>

    <div *ngIf="testSite">
      <p class="lead text-center">
        This is a test environment for training purposes.
      </p>
    </div>
    <div class="row">
      <div class="col-lg-4 offset-lg-4 col-sm-6 offset-sm-3">
        <div class="alert alert-danger" *ngIf="error">
          <p>{{ error }}</p>
        </div>
        <form
          #authForm="ngForm"
          (ngSubmit)="onSubmit(authForm)"
          *ngIf="!isLoading"
        >
          <div class="form-group">
            <label for="email" class="sr-only">Email Address</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><fa-icon
                    [icon]="['fal', 'user']"
                    [fixedWidth]="true"
                  ></fa-icon
                ></span>
              </div>
              <input
                type="email"
                id="email"
                class="form-control"
                ngModel
                name="email"
                placeholder="Email"
                required
                email
                autocomplete="off"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="password" class="sr-only">Password</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><fa-icon
                    [icon]="['fal', 'key']"
                    [fixedWidth]="true"
                  ></fa-icon
                ></span>
              </div>
              <input
                type="password"
                id="password"
                class="form-control"
                ngModel
                name="password"
                required
                minlength="6"
                placeholder="Password"
                autocomplete="off"
              />
            </div>
            <!--label for="inputPassword">Password</label-->
          </div>

          <button
            type="submit"
            [disabled]="!authForm.valid"
            class="btn btn-primary w-100 btn-block btn-lg"
          >
            Sign in
          </button>
          <hr />
          <div class="row">
            <div class="col">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="rememberMe"
                  name="rememberMe"
                  ngModel
                  value="1"
                />
                <label class="form-check-label" for="rememberMe"
                  >Remember me</label
                >
              </div>
            </div>
            <div class="col text-end">
              <a [routerLink]="['/forgotten-password']">Forgotten Password</a>
            </div>
          </div>
        </form>
        <hr />
        <!--span
          >Don’t have an account?
          <a [routerLink]="['/register']">Sign up now</a></span
        -->
      </div>
    </div>
    <div class="container-fluid mt-5 inline-footer">
      <div class="row mt-3 mb-3">
        <div class="col-lg-4 offset-lg-4 col-sm-6 offset-sm-3">
          <div class="row">
            <div class="col">© 2021 Hark 2. <br />All rights reserved.</div>
            <div class="col text-end">
              <a [routerLink]="['/terms-and-conditions']"
                >Terms &amp; Conditions</a
              >
              <br />
              <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
