<div class="fh bg-light pb-10 content-container">
  <app-page-header
    *ngIf="area === 'walking'"
    [title]="'Walking Goals'"
    [icon]="'walking'"
    [backLink]="['/' + this.area]"
  ></app-page-header>

  <app-page-header
    *ngIf="area === 'strength'"
    [title]="'Strength Goals'"
    [icon]="'strength'"
    [backLink]="['/strength']"
  ></app-page-header>

  <app-page-header
    *ngIf="area === 'yoga'"
    [title]="'Yoga Goals'"
    [icon]="'yoga'"
    [backLink]="['/yoga']"
  ></app-page-header>

  <div class="row">
    <div class="col-md-6">
      <div class="card bg-primary-accent">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'star']"></fa-icon>
            </span>
            Current Goals
          </h2>
          <div *ngIf="isLoading" class="text-center">
            <app-loading-spinner [inline]="true"></app-loading-spinner>
          </div>
          <div *ngIf="!isLoading">
          <swiper
            [config]="config"
            (swiper)="onSwiper($event)"
            (slideChange)="onSlideChange()"
            *ngIf="openGoals.length > 0"
          >
            <ng-template swiperSlide *ngFor="let goal of openGoals">
              <div class="goal-content">
                <a class="goal-checkbox" (click)="onCompleteGoal(goal)">
                  <span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'check']"></fa-icon>
                  </span>
                </a>
                <div class="goal-text">
                  {{ goal.goal }}
                </div>
              </div>
            </ng-template>
          </swiper>
          <div
            class="text-center"
            *ngIf="openGoals.length === 0 && allGoals.length > 0"
          >
            No open goals
          </div>
          <div class="text-center" *ngIf="allGoals.length === 0">
            No goals set
          </div>
        </div>
      </div>
      </div>

      <a
        [routerLink]="['/' + this.area, 'add-goal']"
        class="btn btn-secondary rounded-pill w-100 mt-3 mb-3"
        >Add a New Goal</a
      >
    </div>

    <div class="col-md-6">
      <div class="card bg-primary-accent">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'star']"></fa-icon>
            </span>
            Summary
          </h2>
          <div *ngIf="isLoading" class="text-center">
            <app-loading-spinner [inline]="true"></app-loading-spinner>
          </div>
          <div *ngIf="!isLoading">
          <div class="row" *ngIf="allGoals">
            <div class="col-6 mb-3">
              <div class="status-badge">
                <div class="status-badge-icon">
                  <span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'stars']"></fa-icon>
                  </span>
                </div>
                <div class="status-badge-text">
                  <div class="status-badge-title">Goals Created</div>
                  <div class="status-badge-status">{{ allGoals.length }}</div>
                </div>
              </div>
            </div>
            <div class="col-6 mb-3">
              <div class="status-badge">
                <div class="status-badge-icon">
                  <span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'check']"></fa-icon>
                  </span>
                </div>
                <div class="status-badge-text">
                  <div class="status-badge-title">Goals Achieved</div>
                  <div class="status-badge-status">
                    {{ completeGoals.length }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6 mb-3">
              <div class="status-badge">
                <div class="status-badge-icon">
                  <span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
                  </span>
                </div>
                <div class="status-badge-text">
                  <div class="status-badge-title">Quickest Goal</div>
                  <div class="status-badge-status">
                    {{ quickestGoal
                    }}<span> day<span *ngIf="quickestGoal != 1">s</span></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6 mb-3">
              <div class="status-badge">
                <div class="status-badge-icon">
                  <span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'arrows-h']"></fa-icon>
                  </span>
                </div>
                <div class="status-badge-text">
                  <div class="status-badge-title">Longest Streak</div>
                  <div class="status-badge-status">
                    {{ goalStreak
                    }}<span> week<span *ngIf="goalStreak != 1">s</span></span>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div *ngIf="isLoading" class="text-center">
        <app-loading-spinner [inline]="true"></app-loading-spinner>
      </div>
      <div *ngIf="!isLoading">
      <tabset [justified]="true">
        <tab>
          <ng-template tabHeading> All </ng-template>
          <p *ngIf="allGoals.length == 0" class="p-3">No goals</p>

          <ul class="task-list task-list-todo task-list-bt">
            <li *ngFor="let goal of allGoals">
              <span class="task">
                <span class="date me-4 text-center">
                  {{ goal.created | date: "EEE d MMM" }}
                  <div *ngIf="goal.status == 1" class="text-center">
                    <span class="icon-wrap text-secondary-accent">
                      <fa-icon [icon]="['fas', 'check']"></fa-icon>
                    </span>
                  </div>
                </span>
                <span class="title"
                  >{{ goal.goal }}</span
                >
                
                
                <span class="status">
                  <span class="icon-wrap text-secondary-accent" dropdown>
                    <button
                      dropdownToggle
                      type="button"
                      class="btn"
                      aria-controls="dropdown-basic"
                    >
                      <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                    </button>
                    <ul
                      *dropdownMenu
                      class="dropdown-menu dropdown-menu-right"
                      role="menu"
                      aria-labelledby="button-basic"
                    >
                      <li role="menuitem">
                        <a class="dropdown-item" [routerLink]="['/' + this.area, 'edit-goal', goal.goal_id]">Edit</a>
                      </li>
                      <li role="menuitem">
                        <a class="dropdown-item" (click)="onDeleteEntry(goal.goal_id)">Delete</a>
                      </li>
                    </ul>
                  </span>
                </span>
              </span>
            </li>
          </ul>


        </tab>
        <tab>
          <ng-template tabHeading> Open </ng-template>

          <p *ngIf="openGoals.length == 0" class="p-3">No open goals</p>
          <ul class="task-list task-list-todo task-list-bt">
            <li *ngFor="let goal of openGoals">
              <span class="task">
                <span class="date me-4 text-center">
                  {{ goal.created | date: "EEE d MMM" }}
                  <div *ngIf="goal.status == 1" class="text-center">
                    <span class="icon-wrap text-secondary-accent">
                      <fa-icon [icon]="['fas', 'check']"></fa-icon>
                    </span>
                  </div>
                </span>
                <span class="title"
                  >{{ goal.goal }}</span
                >
                
                
                <span class="status">
                  <span class="icon-wrap text-secondary-accent" dropdown>
                    <button
                      dropdownToggle
                      type="button"
                      class="btn"
                      aria-controls="dropdown-basic"
                    >
                      <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                    </button>
                    <ul
                      *dropdownMenu
                      class="dropdown-menu dropdown-menu-right"
                      role="menu"
                      aria-labelledby="button-basic"
                    >
                      <li role="menuitem">
                        <a class="dropdown-item" [routerLink]="['/' + this.area, 'edit-goal', goal.goal_id]">Edit</a>
                      </li>
                      <li role="menuitem">
                        <a class="dropdown-item" (click)="onDeleteEntry(goal.goal_id)">Delete</a>
                      </li>
                    </ul>
                  </span>
                </span>
              </span>
            </li>
          </ul>

        </tab>
        <tab>
          <ng-template tabHeading> Achieved </ng-template>
          <p *ngIf="completeGoals.length == 0" class="p-3">No open goals</p>

          <ul class="task-list task-list-todo task-list-bt">
            <li *ngFor="let goal of completeGoals">
              <span class="task">
                <span class="date me-4 text-center">
                  {{ goal.created | date: "EEE d MMM" }}
                  <div *ngIf="goal.status == 1" class="text-center">
                    <span class="icon-wrap text-secondary-accent">
                      <fa-icon [icon]="['fas', 'check']"></fa-icon>
                    </span>
                  </div>
                </span>
                <span class="title"
                  >{{ goal.goal }}</span
                >
                
                
                <span class="status">
                  <span class="icon-wrap text-secondary-accent" dropdown>
                    <button
                      dropdownToggle
                      type="button"
                      class="btn"
                      aria-controls="dropdown-basic"
                    >
                      <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                    </button>
                    <ul
                      *dropdownMenu
                      class="dropdown-menu dropdown-menu-right"
                      role="menu"
                      aria-labelledby="button-basic"
                    >
                      <li role="menuitem">
                        <a class="dropdown-item" [routerLink]="['/' + this.area, 'edit-goal', goal.goal_id]">Edit</a>
                      </li>
                      <li role="menuitem">
                        <a class="dropdown-item" (click)="onDeleteEntry(goal.goal_id)">Delete</a>
                      </li>
                    </ul>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          
        </tab>
      </tabset>
      </div>
    </div>
  </div>
</div>
