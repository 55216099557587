<nav
  class="navbar fixed-bottom navbar-expand navbar-light navbar-footer"
  aria-label="Main footer navigation"
>
  <div class="d-flex justify-content-center" style="margin: 0 auto">
    <ul class="navbar-nav">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/dashboard']">
          <span class="icon-wrap">
            <app-icon [icon]="'home'"></app-icon>
          </span>
          <span class="nav-label">Dashboard</span>
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/walking']">
          <span class="icon-wrap">
            <app-icon [icon]="'walking'"></app-icon>
          </span>
          <span class="nav-label">Walking</span>
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/strength']">
          <span class="icon-wrap"
            ><app-icon [icon]="'strength'"></app-icon
          ></span>
          <span class="nav-label">Strength</span>
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/yoga']">
          <span class="icon-wrap icon-yoga"
            ><app-icon [icon]="'yoga'"></app-icon
          ></span>
          <span class="nav-label">Yoga</span>
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/managing']">
          <span class="icon-wrap"
            ><app-icon [icon]="'symptoms'"></app-icon
          ></span>
          <span class="nav-label">Symptoms</span>
        </a>
      </li>
    </ul>
  </div>
</nav>
