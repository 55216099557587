import { forkJoin } from "rxjs";
import { UserYogaWorkout } from "./../../models/user-yoga-workout.model";
import { ActivatedRoute } from "@angular/router";
import { YogaWorkoutService } from "./../../services/yoga-workout.service";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { Component, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2/src/sweetalert2.js";
import * as moment from "moment";
import { AchievementService } from "src/app/services/achievement.service";

@Component({
  selector: "app-edit-yoga-workout",
  templateUrl: "./edit-yoga-workout.component.html",
  styleUrls: ["./edit-yoga-workout.component.scss"],
})
export class EditYogaWorkoutComponent implements OnInit {
  @ViewChild("form", { static: false }) entryForm?: NgForm;
  submitted = false;
  error?: string;
  isLoading = false;
  public errors: any = [];
  public entryId!: number;
  public workout?: UserYogaWorkout;
  maxDate = new Date();
  workoutDate?:Date;

  constructor(
    private yogaWorkoutService: YogaWorkoutService,
    private router: Router,
    private route: ActivatedRoute,
    private achievementService: AchievementService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.entryId = params.id;
      this.isLoading = true;
      forkJoin({
        workout: this.yogaWorkoutService.fetch(this.entryId),
      }).subscribe(
        (responseData) => {
          this.isLoading = false;
          this.workout = responseData.workout;
          this.workoutDate = moment(this.workout.workout_date).toDate();
        },
        (error) => {
          this.errors.push(error.message);
        }
      );
    });
  }

  onSubmit() {
    this.submitted = true;
    if (!this.entryForm?.valid) {
      Swal.fire({
        icon: "error",
        title: "The form is invalid",
        text: "Please check the form for errors",
        showConfirmButton: true,
      });
    } else {

    // create entry
    const resourceIds = [];
    this.yogaWorkoutService
      .update(
        this.entryId,
        this.entryForm?.value.workout_date,
        this.entryForm?.value.asana_time,
        this.entryForm?.value.asana_difficulty,
        this.entryForm?.value.pranayama_reps,
        this.entryForm?.value.pranayama_difficulty
      )
      .subscribe(
        (responseData) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Entry updated",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            toast: true,
          });
          this.router.navigate(["/yoga", "workouts"]);
          this.entryForm?.reset();
          this.achievementService.refreshNotifications();
        },
        (error) => {
          this.error = error.message;
        }
      );
    }
  }
}
