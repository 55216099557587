<div class="fh bg-light pb-10 content-container">
  <app-page-header
    [title]="'Walking Guide'"
    [icon]="'walking'"
    [backLink]="['/walking']"
    *ngIf="area =='walking'"
  ></app-page-header>

  <app-page-header
  [title]="'Strength Guide'"
  [icon]="'strength'"
  [backLink]="['/strength']"
  *ngIf="area =='strength'"
></app-page-header>

<app-page-header
[title]="'Yoga Guide'"
[icon]="'yoga'"
[backLink]="['/yoga']"
*ngIf="area =='yoga'"
></app-page-header>

<app-page-header
[title]="'Managing Resources'"
[icon]="'symptoms'"
[backLink]="['/managing']"
*ngIf="area =='managing'"
></app-page-header>



  <div class="row" *ngIf="page">
    <div class="col">
      
  

    <img
    *ngIf="page?.image_filename"
    src="{{ page.image_filename | getThumbnail: 'wide' }}"
    alt="Placeholder image"
    class="rounded-lg img-fluid fade-in d-none d-sm-block mb-4"
  />

  <img
    *ngIf="page?.image_filename"
    src="{{ page.image_filename | getThumbnail: 'medium-landscape' }}"
    alt="Placeholder image"
    class="rounded-lg img-fluid fade-in d-block d-sm-none mb-4"
  />

      <h2 class="inline-title">{{ page.title }}</h2>
      <div (click)="onContentClick($event)">
        <div
          [innerHTML]="page?.content | safeHtml"
          *ngIf="page?.page_type_id === '1'"
        ></div>
        <div *ngIf="page?.page_type_id === '2'">
          <div [innerHTML]="page?.content.content | safeHtml"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="card bg-primary-accent">
        <div class="card-body">
          <h2 class="card-title text-start">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'book-reader']"></fa-icon>
            </span>
            More from this guide
          </h2>
          <ul class="task-list task-list-todo task-list-bt">
            <ng-container *ngFor="let page of pages"
              ><li *ngIf="+page.content_page_id !== +pageId">
                <a
                  class="task"
                  [routerLink]="['/walking', 'guide', page.content_page_id]"
                >
                  <span class="num">
                    <span class="icon-wrap">
                      <fa-icon [icon]="['fas', 'align-left']"></fa-icon>
                    </span>
                  </span>
                  <span class="title">{{ page.title }}</span>
                  <span class="status">
                    <span class="icon-wrap text-secondary-accent">
                      <fa-icon [icon]="['fas', 'arrow-alt-right']"></fa-icon>
                    </span>
                  </span>
                </a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!--div class="fh bg-light pb-10 content-container">
  <app-page-header
    [title]="'Walking Guide'"
    [icon]="'walking'"
    [backLink]="['/walking']"
  ></app-page-header>
  <app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>

  <div class="img-container">
    <img
      [src]="page?.image_filename | getThumbnail: 'wide'"
      alt="Image showing {{ page?.title }}"
      class="img-fluid stage-nav-img resource-masthead-img fade-in d-block d-sm-none"
      *ngIf="page?.image_filename"
    />
    <img
      [src]="page?.image_filename | getThumbnail: 'wide'"
      alt="Image showing {{ page?.title }}"
      class="img-fluid stage-nav-img resource-masthead-img fade-in d-none d-sm-block drop-shadow rounded"
      *ngIf="page?.image_filename"
    />
    <div *ngIf="page?.page_type_id === '2'">
      <video
        width="100%"
        poster="{{ page.content.posterUrl }}"
        controls
        preload
        class="fade-in rounded drop-shadow"
        controlsList="nodownload"
      >
        <source src="{{ page.content.videoUrl }}" type="video/mp4" />
        <track
          src="{{ page.content.captionsUrl }}"
          kind="subtitles"
          srclang="en"
          label="English"
          default
          *ngIf="page.content.captionsUrl"
        />
      </video>
    </div>
  </div>
  <div class="container mt-4 container-resource-page">
    <h3 class="sm-title mb-4 has-prev-next">
      <a
        (click)="onPrev()"
        class="prev pointer"
        *ngIf="prevPage?.content_page_id"
        [routerLink]="['']"
      >
        <span class="sr-only">Previous Page</span>
        <span class="icon-wrap">
          <fa-icon
            [icon]="['far', 'arrow-alt-left']"
            [fixedWidth]="true"
          ></fa-icon>
        </span>
      </a>
      {{ page?.title }}
      <a
        (click)="onNext()"
        class="next pointer"
        *ngIf="nextPage?.content_page_id"
        [routerLink]="['']"
      >
        <span class="sr-only">Next Page</span>
        <span class="icon-wrap">
          <fa-icon
            [icon]="['far', 'arrow-alt-right']"
            [fixedWidth]="true"
          ></fa-icon>
        </span>
      </a>
    </h3>

    <div (click)="onContentClick($event)">
      <div
        [innerHTML]="page?.content | safeHtml"
        *ngIf="page?.page_type_id === '1'"
      ></div>
      <div *ngIf="page?.page_type_id === '2'">
        <div [innerHTML]="page?.content.content | safeHtml"></div>
      </div>
    </div>

    <div class="row prev-next">
      <div class="col text-left">
        <a
          (click)="onPrev()"
          class="prev float-left pointer"
          *ngIf="prevPage?.content_page_id"
          [routerLink]="['']"
        >
          <span class="icon-wrap">
            <fa-icon
              [icon]="['far', 'arrow-alt-left']"
              [fixedWidth]="true"
            ></fa-icon>
          </span>
          Previous
        </a>
      </div>
      <div class="col text-right">
        <a
          (click)="onNext()"
          class="prev float-right pointer"
          *ngIf="nextPage?.content_page_id"
          [routerLink]="['']"
        >
          Next
          <span class="icon-wrap">
            <fa-icon
              [icon]="['far', 'arrow-alt-right']"
              [fixedWidth]="true"
            ></fa-icon>
          </span>
        </a>
      </div>
    </div>
  </div>
</div-->
