<div class="fh bg-light pb-10 content-container">
  <app-page-header
    [title]="'Add Yoga Workout'"
    [icon]="'yoga'"
    [backLink]="['/yoga']"
  ></app-page-header>

  <div class="row">
    <div class="col">
      <form class="form-recharge" (ngSubmit)="onSubmit()" #form="ngForm">
        <div class="form-group">
          <label for="date text-center">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
            </span>
            Date</label
          >

          <input
            type="text"
            id="workout_date"
            class="form-control"
            [ngModel]="bsDate"
            #workout_date="ngModel"
            name="workout_date"
            placeholder="DD/MM/YYYY"
            required
            date
            bsDatepicker
            autocomplete="off" 
            [maxDate]="maxDate"
            [bsConfig]="{
              dateInputFormat: 'DD/MM/YYYY',
              containerClass: 'theme-recharge',
              showWeekNumbers: false
            }"
          />
          <div
          class="alert alert-danger"
          *ngIf="!workout_date.valid && submitted"
            >
              Please enter a date above
          </div>
        </div>

        <div class="form-group">
          <label> Asana</label>
          <div class="col-sm-6 mb-2">
            <label class="sub-label">Holding time</label>
            <div class="radio-group radio-group-text"  [ngClass]="{'chosen':asana_time.dirty || asana_time.touched}">
              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #asana_time="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="asana_time"
                  id="asana_time1"
                  value="0"
                />
                <label class="form-check-label" for="asana_time1">0 <span>secs</span></label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #asana_time="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="asana_time"
                  id="asana_time2"
                  value="1-7"
                />
                <label class="form-check-label" for="asana_time2"
                  >1-7 <span>secs</span></label
                >
              </div>

              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #asana_time="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="asana_time"
                  id="asana_time3"
                  value="8-14"
                />
                <label class="form-check-label" for="asana_time3"
                  >8-14 <span>secs</span></label
                >
              </div>

              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #asana_time="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="asana_time"
                  id="asana_time4"
                  value="15+"
                />
                <label class="form-check-label" for="asana_time4"
                  >15+ <span>secs</span></label
                >
              </div>
            </div>
            <div
            class="alert alert-danger"
            *ngIf="!asana_time.valid && submitted"
              >
                Please select a value above
            </div>
          </div>

          <div class="col-sm-6 input-difficulty">
            <label class="sub-label">Difficulty</label>

            <div class="radio-group"  [ngClass]="{'chosen':asana_difficulty.dirty || asana_difficulty.touched}">


              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #asana_difficulty="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="asana_difficulty"
                  id="asana_difficulty1"
                  value="1"
                />
                <label class="form-check-label" for="asana_difficulty1"
                  ><span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'grin-stars']"></fa-icon> </span
                ></label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #asana_difficulty="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="asana_difficulty"
                  id="asana_difficulty2"
                  value="2"
                />
                <label class="form-check-label" for="asana_difficulty2"
                  ><span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'smile']"></fa-icon> </span
                ></label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #asana_difficulty="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="asana_difficulty"
                  id="asana_difficulty3"
                  value="3"
                />
                <label class="form-check-label" for="asana_difficulty3"
                  ><span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'meh']"></fa-icon> </span
                ></label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #asana_difficulty="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="asana_difficulty"
                  id="asana_difficulty4"
                  value="4"
                />
                <label class="form-check-label" for="asana_difficulty4"
                  ><span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'frown']"></fa-icon> </span
                ></label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  ngModel
                  #asana_difficulty="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="asana_difficulty"
                  id="asana_difficulty5"
                  value="5"
                />
                <label class="form-check-label" for="asana_difficulty5"
                  ><span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'tired']"></fa-icon> </span
                ></label>
              </div>

            </div>
          </div>
          <div
          class="alert alert-danger"
          *ngIf="!asana_difficulty.valid && submitted"
            >
              Please select a value above
          </div>
        </div>


        <div class="form-group">
            <label> Pranayama</label>
            <div class="col-sm-6 mb-2">
              <label class="sub-label">Repetitions</label>
              <div class="radio-group radio-group-text"  [ngClass]="{'chosen':pranayama_reps.dirty || pranayama_reps.touched}">
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #pranayama_reps="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="pranayama_reps"
                    id="pranayama_reps1"
                    value="0"
                  />
                  <label class="form-check-label" for="pranayama_reps1">0 <span>reps</span></label>
                </div>
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #pranayama_reps="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="pranayama_reps"
                    id="pranayama_reps2"
                    value="1-7"
                  />
                  <label class="form-check-label" for="pranayama_reps2"
                    >1-7 <span>reps</span></label
                  >
                </div>
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #pranayama_reps="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="pranayama_reps"
                    id="pranayama_reps3"
                    value="8-12"
                  />
                  <label class="form-check-label" for="pranayama_reps3"
                    >8-12 <span>reps</span></label
                  >
                </div>
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #pranayama_reps="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="pranayama_reps"
                    id="pranayama_reps4"
                    value="13+"
                  />
                  <label class="form-check-label" for="pranayama_reps4"
                    >13+ <span>reps</span></label
                  >
                </div>
              </div>
              <div
              class="alert alert-danger"
              *ngIf="!pranayama_reps.valid && submitted"
                >
                  Please select a value above
              </div>
            </div>
  
            <div class="col-sm-6 input-difficulty">
              <label class="sub-label">Difficulty</label>
  
              <div class="radio-group"  [ngClass]="{'chosen':pranayama_difficulty.dirty || pranayama_difficulty.touched}">
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #pranayama_difficulty="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="pranayama_difficulty"
                    id="pranayama_difficulty1"
                    value="1"
                  />
                  <label class="form-check-label" for="pranayama_difficulty1"
                    ><span class="icon-wrap">
                      <fa-icon [icon]="['fas', 'grin-stars']"></fa-icon> </span
                  ></label>
                </div>


  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #pranayama_difficulty="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="pranayama_difficulty"
                    id="pranayama_difficulty2"
                    value="2"
                  />
                  <label class="form-check-label" for="pranayama_difficulty2"
                    ><span class="icon-wrap">
                      <fa-icon [icon]="['fas', 'smile']"></fa-icon> </span
                  ></label>
                </div>
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #pranayama_difficulty="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="pranayama_difficulty"
                    id="pranayama_difficulty3"
                    value="3"
                  />
                  <label class="form-check-label" for="pranayama_difficulty3"
                    ><span class="icon-wrap">
                      <fa-icon [icon]="['fas', 'meh']"></fa-icon> </span
                  ></label>
                </div>
  
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #pranayama_difficulty="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="pranayama_difficulty"
                    id="pranayama_difficulty4"
                    value="4"
                  />
                  <label class="form-check-label" for="pranayama_difficulty4"
                    ><span class="icon-wrap">
                      <fa-icon [icon]="['fas', 'frown']"></fa-icon> </span
                  ></label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #pranayama_difficulty="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="pranayama_difficulty"
                    id="pranayama_difficulty5"
                    value="5"
                  />
                  <label class="form-check-label" for="pranayama_difficulty5"
                    ><span class="icon-wrap">
                      <fa-icon [icon]="['fas', 'tired']"></fa-icon> </span
                  ></label>
                </div>


              </div>
            </div>
            <div
            class="alert alert-danger"
            *ngIf="!pranayama_difficulty.valid && submitted"
              >
                Please select a value above
            </div>
          </div>

        <button class="btn btn-secondary rounded-pill w-100">
          Save Yoga Workout
        </button>
      </form>
    </div>
  </div>
</div>
