import { YogaWorkoutService } from "./../../services/yoga-workout.service";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { Component, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2/src/sweetalert2.js";
import { AchievementService } from "src/app/services/achievement.service";

@Component({
  selector: "app-add-yoga-workout",
  templateUrl: "./add-yoga-workout.component.html",
  styleUrls: ["./add-yoga-workout.component.scss"],
})
export class AddYogaWorkoutComponent implements OnInit {
  @ViewChild("form", { static: false }) entryForm?: NgForm;
  submitted = false;
  error?: string;
  maxDate = new Date();
  bsDate = new Date();

  constructor(
    private yogaWorkoutService: YogaWorkoutService,
    private router: Router,
    private achievementService: AchievementService
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    this.submitted = true;
    if (!this.entryForm?.valid) {
      Swal.fire({
        icon: "error",
        title: "The form is invalid",
        text: "Please check the form for errors",
        showConfirmButton: true,
      });
    } else {
      // create entry
      const resourceIds = [];
      this.yogaWorkoutService
        .create(
          this.entryForm?.value.workout_date,
          this.entryForm?.value.asana_time,
          this.entryForm?.value.asana_difficulty,
          this.entryForm?.value.pranayama_reps,
          this.entryForm?.value.pranayama_difficulty
        )
        .subscribe(
          (responseData) => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Entry created",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              toast: true,
            });
            this.router.navigate(["/yoga"]);
            this.entryForm?.reset();
            this.achievementService.refreshNotifications();
          },
          (error) => {
            this.error = error.message;
          }
        );
    }
  }
}
