import { UserSymptom } from './../../models/user-symptom.model';
import { forkJoin } from 'rxjs';
import { Router, ActivatedRoute } from "@angular/router";
import { SymptomService } from "./../../services/symptom.service";
import { NgForm } from "@angular/forms";
import { Component, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2/src/sweetalert2.js";

@Component({
  selector: 'app-update-symptoms',
  templateUrl: './update-symptoms.component.html',
  styleUrls: ['./update-symptoms.component.scss']
})
export class UpdateSymptomsComponent implements OnInit {
  @ViewChild("form", { static: false }) entryForm?: NgForm;
  submitted = false;
  isLoading = false;
  public normalSymptoms!: UserSymptom | undefined;
  public errors: any = [];
  public error?:any;

  constructor(
    private symptomService: SymptomService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    forkJoin({
      normalSymptoms: this.symptomService.fetchNormal(),
    }).subscribe(
      (responseData) => {
        this.isLoading = false;
        this.normalSymptoms = responseData.normalSymptoms;
      },
      (error) => {
        this.errors.push(error.message);
      }
    );}

  onSubmit() {
    this.submitted = true;

    if(!this.entryForm?.valid){
      Swal.fire({
        icon: "error",
        title: "The form is invalid",
        text: "Please check the form for errors",
        showConfirmButton: true,
      });
    }
    else{
    // create entry
    const resourceIds = [];
    this.symptomService
      .create(
        this.entryForm?.value.breathlessness_rest,
        this.entryForm?.value.breathlessness_active,
        this.entryForm?.value.sputum,
        this.entryForm?.value.sputum_colour,
        this.entryForm?.value.wheeze,
        this.entryForm?.value.fever,
        this.entryForm?.value.wellbeing
      )
      .subscribe(
        (responseData) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Symptoms updated",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            toast: true,
          });
          this.router.navigate(["/managing"]);
          this.entryForm?.reset();
        },
        (error) => {
          this.error = error.message;
          this.entryForm?.reset();
        }
      );
    }
  }
}
