import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class DateHelperService {
  constructor() {}

  getCurrentWeek() {
    const now = moment();
    return this.getWeekFor(now);
  }

  getLast4tWeeks() {
    var weekStart = moment().subtract(3, "weeks").startOf("isoWeek");
    var days = [];
    for (var i = 0; i < 28; i++) {
      days.push(weekStart.clone().add(i, "day").toDate());
    }
    return days;
  }
  getWeekKey(week:any){
    return moment(week[0]).format('YYYY-MM-DD') + '_' + moment(week[6]).format('YYYY-MM-DD');
  }

  getWeekFor(dateTime: moment.Moment): Date[] {
    const days = [];
    const monday = moment(dateTime).startOf("isoWeek");
    days.push(monday.toDate());
    for (let i = 1; i < 7; i++) {
      days.push(moment(monday).add(i, "days").toDate());
    }
    return days;
  }

  getWeekDayFor(dateTime: moment.Moment): Date[] {
    const days = [];
    const monday = moment(dateTime).startOf("isoWeek");
    days.push(monday.toDate());

    for (let i = 1; i < 5; i++) {
      days.push(moment(monday).add(i, "days").toDate());
    }

    return days;
  }

  shiftWeek(add: number, dateTime: moment.Moment) {
    // this will just increment or decrement the week
    const sunday = moment(dateTime).startOf("isoWeek");
    sunday.add(1, "d");

    if (add) {
      sunday.add(1, "w");
    } else {
      sunday.subtract(1, "w");
    }

    return sunday;
  }

  getWeekDate(dateTime: moment.Moment) {
    const sunday = moment(dateTime).startOf("isoWeek");

    const monday = sunday.add({ day: 1 }).clone();

    return "Week Commencing " + monday.format("Do");
  }

  getStartOfWeek(dateTime: moment.Moment) {
    const sunday = moment(dateTime).startOf("isoWeek");

    const monday = sunday.add({ day: 1 }).clone();

    return monday;
  }

  formatDate(date: any, format: string) {
    return moment(date).format(format);
  }

  getToday() {
    return moment().format("YYYY-MM-DD");
  }
}
