<div class="fh bg-light content-container">
  <app-page-header
    [title]="'Symptoms'"
    [icon]="'symptoms'"
    [backLink]="['/managing']"
  ></app-page-header>
  <div *ngIf="isLoading" class="text-center">
    <app-loading-spinner></app-loading-spinner>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="card bg-primary-accent mb-3">
        <div class="card-body">
          <h2 class="card-title text-start">Latest Entry</h2>
          <div *ngIf="isLoading" class="text-center">
            <app-loading-spinner [inline]="true"></app-loading-spinner>
          </div>
          <div *ngIf="!isLoading">

          <div *ngIf="!normalSymptoms">
            <p>Please set your usual symptoms to begin.</p>
          </div>
          <div *ngIf="normalSymptoms">
            <div *ngIf="userSymptoms.length == 0">
              <p>
                You have not updated your symptoms yet. If you symptoms change
                please report those changes here.
              </p>
            </div>
            <div *ngIf="userSymptoms.length > 0">
              <p>Changes compared to your normal levels.</p>
              <div class="row">
                <div class="col">
                  <div class="symptom-status">
                    <div class="symptom-status-label">Increased</div>
                    <div class="symptom-status-box">
                      <div class="symptom-status-icon text-danger">
                        <fa-icon [icon]="['fas', 'temperature-up']"></fa-icon>
                      </div>
                      <div class="symptom-status-value">
                        {{ userSymptoms[0].total_up }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="symptom-status">
                    <div class="symptom-status-label">Normal</div>
                    <div class="symptom-status-box">
                      <div class="symptom-status-icon text-warning">
                        <fa-icon [icon]="['fas', 'temperature-down']"></fa-icon>
                      </div>
                      <div class="symptom-status-value">
                        {{ userSymptoms[0].total_down }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="symptom-status">
                    <div class="symptom-status-label">Wellbeing</div>
                    <div class="symptom-status-box">
                      <div class="symptom-status-icon">
                        <fa-icon
                          [icon]="['far', 'head-side-medical']"
                        ></fa-icon>
                      </div>
                      <div class="symptom-status-value">
                        <span
                          class="wellbeing-circle bg-symptom-{{
                            +userSymptoms[0].wellbeing + 1
                          }}"
                          ><span class="visually-hidden">{{
                            userSymptoms[0].wellbeing
                          }}</span></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <p class="subtext">
                Last updated:
                {{ userSymptoms[0].symptom_date | date: "EEE d MMM" }}
              </p>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button
        class="btn btn-secondary rounded-pill w-100"
        [routerLink]="['/managing', 'update-symptoms']"
      >
        Update Current Symptoms
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="text-center">
    <app-loading-spinner [inline]="true"></app-loading-spinner>
  </div>
  <div *ngIf="!isLoading">
  <div class="row">
    <div class="col">
      <h2 class="inline-title">
        <span class="float-end muted"
          >{{ userSymptoms?.length }}
          <span *ngIf="userSymptoms?.length != 1">entries</span
          ><span *ngIf="userSymptoms?.length == 1">entry</span></span
        >Symptom Entries
      </h2>

      <div *ngFor="let month of entryMonths">
        <h3 class="month-header">{{ month | slice: 0:-2 }}</h3>
        <ul class="task-list task-list-todo task-list-bt mb-2">
          <ng-container  *ngFor="let userSymptom of userSymptoms">
        <li
          *ngIf="(userSymptom.symptom_date | date: 'MMMMYY') == month"
          [routerLink]="[
            '/managing',
            'symptoms',
            'entry',
            userSymptom.user_symptom_id
          ]"
        >
          <span class="task">
            <span class="date me-4">
              {{ userSymptom.symptom_date | date: "EEE d MMM" }}
            </span>
            <span class="title"
              ><span class="text-danger icon-wrap"
                ><fa-icon [icon]="['fas', 'temperature-up']"></fa-icon
              ></span>
              {{ userSymptom.total_up }}</span
            >
            <span class="title"
              ><span class="text-warning icon-wrap"
                ><fa-icon [icon]="['fas', 'temperature-down']"></fa-icon> </span
              >{{ userSymptom.total_down }}</span
            >
            <span class="title"
              ><fa-icon [icon]="['far', 'head-side-medical']"></fa-icon
              >&nbsp;<span
                class="wellbeing-circle bg-symptom-{{
                  +userSymptom.wellbeing + 1
                }}"
                ><span class="visually-hidden">{{
                  userSymptom.wellbeing
                }}</span></span
              ></span
            >
            <span class="status">
              <span class="icon-wrap text-secondary-accent">
                <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
              </span>
            </span>
          </span>
        </li>
        </ng-container>
      </ul>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="card bg-primary mb-3">
        <div class="card-body">
          <h2 class="card-title text-start">Usual Symptoms Changed?</h2>

            <p>Usual symptoms can be updated, but only if
              they have changed over a longer period.</p>
          
        </div>
        <div class="card-footer bg-secondary relative">
          <a
          [routerLink]="['/managing', 'set-normal-symptoms']"
          class="card-link stretched-link" 
          >Change Usual Symptoms</a
        >
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
