<div class="fh bg-light pb-10 content-container">
  <app-page-header
    [title]="'Edit Strength Workout'"
    [icon]="'strength'"
    [backLink]="['/strength']"
  ></app-page-header>

  <div class="row">
    <div class="col">
      <form class="form-recharge" (ngSubmit)="onSubmit()" #form="ngForm">
        <div class="form-group">
          <label for="date text-center">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
            </span>
            Date</label
          >

          <input
            type="text"
            id="workout_date"
            class="form-control"
            [ngModel]="workoutDate"
            #workout_date="ngModel"
            name="workout_date"
            placeholder="DD/MM/YYYY"
            required
            date
            bsDatepicker
            autocomplete="off" 
            [maxDate]="maxDate"
            [bsConfig]="{
              dateInputFormat: 'DD/MM/YYYY',
              containerClass: 'theme-recharge',
              showWeekNumbers: false
            }"
          /><div
          class="alert alert-danger"
          *ngIf="!workout_date.valid && submitted"
            >
              Please enter a date above
          </div>
        </div>

        <div class="form-group">
          <label for="date text-center">
            Weight (Kg)</label
          >

          <input
            type="number"
            id="weight"
            class="form-control"
            [ngModel]="workout?.weight"
            #weight="ngModel"
            name="weight"
            placeholder="e.g. 1.5Kg"
            required
          />

          <div
          class="alert alert-danger"
          *ngIf="!weight.valid && submitted"
            >
              Please enter a weight above
          </div>
        </div>

        <div class="form-group">
          <label>
           
            REPETITIONS</label
          >
          <div class="col-sm-6 mb-2">
            <label class="sub-label">Set 1</label>
            <div class="radio-group radio-group-text" [ngClass]="{'chosen':set1_reps.dirty || set1_reps.touched || workout?.set1_reps}">
              <div class="form-check form-check-inline">
                <input
                  [ngModel]="workout?.set1_reps"
                  #set1_reps="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="set1_reps"
                  id="set1_reps1"
                  [value]="'0'"
                />
                <label class="form-check-label" for="set1_reps1">0 reps</label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  [ngModel]="workout?.set1_reps"
                  #set1_reps="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="set1_reps"
                  id="set1_reps2"
                  [value]="'1-7'"
                />
                <label class="form-check-label" for="set1_reps2"
                  >1-7 reps</label
                >
              </div>

              <div class="form-check form-check-inline">
                <input
                  [ngModel]="workout?.set1_reps"
                  #set1_reps="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="set1_reps"
                  id="set1_reps3"
                  [value]="'8-12'"
                />
                <label class="form-check-label" for="set1_reps3"
                  >8-12 reps</label
                >
              </div>

              <div class="form-check form-check-inline">
                <input
                  [ngModel]="workout?.set1_reps"
                  #set1_reps="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="set1_reps"
                  id="set1_reps4"
                  [value]="'13+'"
                />
                <label class="form-check-label" for="set1_reps4"
                  >13+ reps</label
                >
              </div>
            </div><div
            class="alert alert-danger"
            *ngIf="!set1_reps.valid && submitted"
              >
                Please select a value above
            </div>
          </div>

          <div class="col-sm-6 mb-2">
            <label class="sub-label">Set 2</label>
            <div class="radio-group radio-group-text" [ngClass]="{'chosen':set2_reps.dirty || set2_reps.touched || workout?.set2_reps}">
              <div class="form-check form-check-inline">
                <input
                  [ngModel]="workout?.set2_reps"
                  #set2_reps="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="set2_reps"
                  id="set2_reps1"
                  [value]="'0'"
                />
                <label class="form-check-label" for="set2_reps1">0 reps</label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  [ngModel]="workout?.set2_reps"
                  #set2_reps="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="set2_reps"
                  id="set2_reps2"
                  [value]="'1-7'"
                />
                <label class="form-check-label" for="set2_reps2"
                  >1-7 reps</label
                >
              </div>

              <div class="form-check form-check-inline">
                <input
                  [ngModel]="workout?.set2_reps"
                  #set2_reps="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="set2_reps"
                  id="set2_reps3"
                  [value]="'8-12'"
                />
                <label class="form-check-label" for="set2_reps3"
                  >8-12 reps</label
                >
              </div>

              <div class="form-check form-check-inline">
                <input
                  [ngModel]="workout?.set2_reps"
                  #set2_reps="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="set2_reps"
                  id="set2_reps4"
                  [value]="'13+'"
                />
                <label class="form-check-label" for="set2_reps4"
                  >13+ reps</label
                >
              </div>
            </div><div
            class="alert alert-danger"
            *ngIf="!set2_reps.valid && submitted"
              >
                Please select a value above
            </div>
          </div>

          <div class="col-sm-6 mb-2">
            <label class="sub-label">Set 3</label>
            <div class="radio-group radio-group-text" [ngClass]="{'chosen':set3_reps.dirty || set3_reps.touched || workout?.set3_reps}">
              <div class="form-check form-check-inline">
                <input
                  [ngModel]="workout?.set3_reps"
                  #set3_reps="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="set3_reps"
                  id="set3_reps1"
                  [value]="'0'"
                />
                <label class="form-check-label" for="set3_reps1">0 reps</label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  [ngModel]="workout?.set3_reps"
                  #set3_reps="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="set3_reps"
                  id="set3_reps2"
                  [value]="'1-7'"
                />
                <label class="form-check-label" for="set3_reps2"
                  >1-7 reps</label
                >
              </div>

              <div class="form-check form-check-inline">
                <input
                  [ngModel]="workout?.set3_reps"
                  #set3_reps="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="set3_reps"
                  id="set3_reps3"
                  [value]="'8-12'"
                />
                <label class="form-check-label" for="set3_reps3"
                  >8-12 reps</label
                >
              </div>

              <div class="form-check form-check-inline">
                <input
                  [ngModel]="workout?.set3_reps"
                  #set3_reps="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="set3_reps"
                  id="set3_reps4"
                  [value]="'13+'"
                />
                <label class="form-check-label" for="set3_reps4"
                  >13+ reps</label
                >
              </div>
            </div><div
            class="alert alert-danger"
            *ngIf="!set3_reps.valid && submitted"
              >
                Please select a value above
            </div>
          </div>

          <div class="col-sm-6 input-difficulty">
            <label class="sub-label">Difficulty</label>

            <div class="radio-group"  [ngClass]="{'chosen':difficulty.dirty || difficulty.touched || workout?.difficulty}">
              <div class="form-check form-check-inline">
                <input
                  [ngModel]="workout?.difficulty"
                  #difficulty="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="difficulty"
                  id="difficulty1"
                  [value]="1"
                />
                <label class="form-check-label" for="difficulty1"
                  ><span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'grin-stars']"></fa-icon> </span
                ></label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  [ngModel]="workout?.difficulty"
                  #difficulty="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="difficulty"
                  id="difficulty2"
                  [value]="2"
                />
                <label class="form-check-label" for="difficulty2"
                  ><span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'smile']"></fa-icon> </span
                ></label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  [ngModel]="workout?.difficulty"
                  #difficulty="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="difficulty"
                  id="difficulty3"
                  [value]="3"
                />
                <label class="form-check-label" for="difficulty3"
                  ><span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'meh']"></fa-icon> </span
                ></label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  [ngModel]="workout?.difficulty"
                  #difficulty="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="difficulty"
                  id="difficulty4"
                  [value]="4"
                />
                <label class="form-check-label" for="difficulty4"
                  ><span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'frown']"></fa-icon> </span
                ></label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  [ngModel]="workout?.difficulty"
                  #difficulty="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="difficulty"
                  id="difficulty5"
                  [value]="5"
                />
                <label class="form-check-label" for="difficulty5"
                  ><span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'tired']"></fa-icon> </span
                ></label>
              </div>
            </div>
          </div>
          <div
          class="alert alert-danger"
          *ngIf="!difficulty.valid && submitted"
            >
              Please select a value above
          </div>
        </div>

        <button class="btn btn-secondary rounded-pill w-100">
          Update Strength Workout
        </button>
      </form>
    </div>
  </div>
</div>
