import { StrengthWorkoutService } from "./../../services/strength-workout.service";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { Component, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2/src/sweetalert2.js";
import { AchievementService } from "src/app/services/achievement.service";

@Component({
  selector: "app-add-strength-workout",
  templateUrl: "./add-strength-workout.component.html",
  styleUrls: ["./add-strength-workout.component.scss"],
})
export class AddStrengthWorkoutComponent implements OnInit {
  @ViewChild("form", { static: false }) entryForm?: NgForm;
  submitted = false;
  error?: string;
  maxDate = new Date();
  bsDate = new Date();

  constructor(
    private strengthWorkoutService: StrengthWorkoutService,
    private router: Router,
    private achievementService: AchievementService
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    this.submitted = true;
    if (!this.entryForm?.valid) {
      Swal.fire({
        icon: "error",
        title: "The form is invalid",
        text: "Please check the form for errors",
        showConfirmButton: true,
      });
    } else {
      // create entry
      const resourceIds = [];
      this.strengthWorkoutService
        .create(
          this.entryForm?.value.workout_date,
          this.entryForm?.value.weight,
          this.entryForm?.value.set1_reps,
          this.entryForm?.value.set2_reps,
          this.entryForm?.value.set3_reps,
          this.entryForm?.value.difficulty
        )
        .subscribe(
          (responseData) => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Entry created",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              toast: true,
            });
            this.router.navigate(["/strength"]);
            this.entryForm?.reset();
            this.achievementService.refreshNotifications();
          },
          (error) => {
            this.error = error.message;
          }
        );
    }
  }
}
