<div class="fh bg-light pb-10 content-container">
  <app-page-header
    [title]="'Edit Walking Workout'"
    [icon]="'walking'"
    [backLink]="['/walking']"
  ></app-page-header>

  <div class="row">
    <div class="col">
      <form class="form-recharge" (ngSubmit)="onSubmit()" #form="ngForm">
        <div class="form-group">
          <label for="date text-center">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
            </span>
            Date</label
          >

          <input
            type="text"
            id="date"
            class="form-control"
            [ngModel]="workoutDate"
            #date="ngModel"
            name="date"
            placeholder="DD/MM/YYYY"
            required
            date
            bsDatepicker
            autocomplete="off" 
            [maxDate]="maxDate"
            [bsConfig]="{
              dateInputFormat: 'DD/MM/YYYY',
              containerClass: 'theme-recharge',
              showWeekNumbers: false
            }"
          />
          <div
          class="alert alert-danger"
          *ngIf="!date.valid && submitted"
            >
              Please enter a date above
          </div>
        </div>

        <div class="form-group">
          <label>
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'stopwatch']"></fa-icon>
            </span>
            WALKING ACTIVITY</label
          >
          <div class="col-sm-6 mb-2">
            <div class="row">
              <div class="col">

                <label for="minutes" class="sub-label">Minutes walked</label>
                <input
                  type="number"
                  id="minutes"
                  class="form-control"
                  [ngModel]="userWalk?.minutes"
                  #minutes="ngModel"
                  name="minutes"
                  placeholder="0"
                  minutes
                />
                <div
                class="alert alert-danger"
                *ngIf="!minutes.valid && submitted"
                  >
                    Please enter minutes above
                </div>

              </div>
              <div class="col">
                <label for="seconds" class="sub-label">Seconds walked</label>
                <input
                  type="number"
                  id="seconds"
                  class="form-control"
                  ngModel
                  #seconds="ngModel"
                  name="seconds"
                  placeholder="0"
                  min="0" 
                  max="59"
                  seconds
                />
                <div
                class="alert alert-danger"
                *ngIf="!seconds.valid && seconds.touched"
              >
                Please enter a number of seconds walked (0-59)
              </div>
              </div>
            </div>
            
          </div>
          <div class="col-sm-6 input-difficulty">
            <label class="sub-label">Difficulty</label>

            <div class="radio-group"  [ngClass]="{'chosen':difficulty.dirty || difficulty.touched || userWalk?.difficulty}">
              <div class="form-check form-check-inline">
                <input
                  [ngModel]="userWalk?.difficulty"
                  #difficulty="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="difficulty"
                  id="difficulty1"
                  [value]="1"
                />
                <label class="form-check-label" for="difficulty1"
                  ><span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'grin-stars']"></fa-icon> </span
                ></label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  [ngModel]="userWalk?.difficulty"
                  #difficulty="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="difficulty"
                  id="difficulty2"
                  [value]="2"
                />
                <label class="form-check-label" for="difficulty2"
                  ><span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'smile']"></fa-icon> </span
                ></label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  [ngModel]="userWalk?.difficulty"
                  #difficulty="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="difficulty"
                  id="difficulty3"
                  [value]="3"
                />
                <label class="form-check-label" for="difficulty3"
                  ><span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'meh']"></fa-icon> </span
                ></label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  [ngModel]="userWalk?.difficulty"
                  #difficulty="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="difficulty"
                  id="difficulty4"
                  [value]="4"
                />
                <label class="form-check-label" for="difficulty4"
                  ><span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'frown']"></fa-icon> </span
                ></label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  [ngModel]="userWalk?.difficulty"
                  #difficulty="ngModel"
                  class="form-check-input"
                  type="radio"
                  required
                  name="difficulty"
                  id="difficulty5"
                  [value]="5"
                />
                <label class="form-check-label" for="difficulty5"
                  ><span class="icon-wrap">
                    <fa-icon [icon]="['fas', 'tired']"></fa-icon> </span
                ></label>
              </div>
            </div>

            <div
              class="alert alert-danger"
              *ngIf="!difficulty.valid && submitted"
            >
              Please select a difficulty above
            </div>
          </div>
        </div>

        <button class="btn btn-secondary rounded-pill w-100">
          Save Walking Entry
        </button>
      </form>
    </div>
  </div>
</div>
