import { AchievementService } from "./../services/achievement.service";
import { UserSymptom } from "./../models/user-symptom.model";
import { SymptomService } from "./../services/symptom.service";
import { WeeklyTask } from "./../models/weekly-task.model";
import { Goal } from "./../models/goal.model";
import { UserWeeklyTarget } from "./../models/user-weekly-target.model";
import { UserService } from "src/app/auth/service/user.service";
import { forkJoin } from "rxjs";
import { WeeklyTargetService } from "./../services/weekly-target.service";
import { DateHelperService } from "src/app/services/date-helper.service";
import { Component, OnInit } from "@angular/core";
import { GoalService } from "../services/goal.service";
import { UserAchievement } from "../models/user-achievement.model";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  isLoading = false;
  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public today = this.dateHelperService.getToday();
  public allGoals?: Goal[];
  public goalStatus: any;
  public errors: any = [];
  public weeklyTargets!: UserWeeklyTarget | undefined;
  public weeklyTasks: WeeklyTask[] = [];
  public userSymptoms!: UserSymptom[];
  public normalSymptoms!: UserSymptom | undefined;
  public topAchievements!: UserAchievement[];

  constructor(
    private dateHelperService: DateHelperService,
    private weeklyTargetService: WeeklyTargetService,
    private userService: UserService,
    private goalService: GoalService,
    private symptomService: SymptomService,
    private achievementService: AchievementService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    const startDate = this.currentWeek[0];
    const endDate = this.currentWeek[6];
    const weekKey = this.dateHelperService.getWeekKey(this.currentWeek);
    forkJoin({
      weeklyTargets: this.weeklyTargetService.fetchWeek(weekKey),
      allGoals: this.goalService.fetchAll(),
      allSymptoms: this.symptomService.fetchBetween(startDate, endDate),
      normalSymptoms: this.symptomService.fetchNormal(),
      topAchievements: this.achievementService.fetchAll(),
    }).subscribe(
      (responseData) => {
        this.isLoading = false;
        this.weeklyTargets = responseData.weeklyTargets;
        this.allGoals = responseData.allGoals;
        this.goalStatus = this.goalService.getWeeklyStatus(this.allGoals);
        this.userSymptoms = responseData.allSymptoms;
        this.normalSymptoms = responseData.normalSymptoms;
        this.topAchievements = responseData.topAchievements;

        // now set the tasks

        if (this.weeklyTargets) {
          // walking tasks
          this.weeklyTasks.push({
            area: "walking",
            icon: "walking",
            title: "Complete the weekly target",
            status: +this.weeklyTargets.walking,
            url: ["/walking"],
          });
          if (this.goalStatus.walking == 0) {
            this.weeklyTasks.push({
              area: "walking",
              icon: "walking",
              title: "Add a goal",
              status: 0,
              url: ["/walking", "add-goal"],
            });
          }
          if (this.goalStatus.walking == 1) {
            this.weeklyTasks.push({
              area: "walking",
              icon: "walking",
              title: "Add a goal",
              status: 1,
              url: ["/walking", "add-goal"],
            });
            this.weeklyTasks.push({
              area: "walking",
              icon: "walking",
              title: "Update goal progress",
              status: 0,
              url: ["/walking"],
            });
          }
          if (this.goalStatus.walking == 2) {
            this.weeklyTasks.push({
              area: "walking",
              icon: "walking",
              title: "Add a goal",
              status: 1,
              url: ["/walking", "add-goal"],
            });
            this.weeklyTasks.push({
              area: "walking",
              icon: "walking",
              title: "Update goal progress",
              status: 1,
              url: ["/walking"],
            });
          }

          this.weeklyTasks.push({
            area: "strength",
            icon: "strength",
            title: "Complete the weekly target",
            status: +this.weeklyTargets.strength,
            url: ["/strength"],
          });
          if (this.goalStatus.strength == 0) {
            this.weeklyTasks.push({
              area: "strength",
              icon: "strength",
              title: "Add a goal",
              status: 0,
              url: ["/strength", "add-goal"],
            });
          }
          if (this.goalStatus.strength == 1) {
            this.weeklyTasks.push({
              area: "strength",
              icon: "strength",
              title: "Add a goal",
              status: 1,
              url: ["/strength", "add-goal"],
            });
            this.weeklyTasks.push({
              area: "strength",
              icon: "strength",
              title: "Update goal progress",
              status: 0,
              url: ["/strength"],
            });
          }
          if (this.goalStatus.strength == 2) {
            this.weeklyTasks.push({
              area: "strength",
              icon: "strength",
              title: "Add a goal",
              status: 1,
              url: ["/strength", "add-goal"],
            });
            this.weeklyTasks.push({
              area: "strength",
              icon: "strength",
              title: "Update goal progress",
              status: 1,
              url: ["/strength"],
            });
          }

          this.weeklyTasks.push({
            area: "yoga",
            icon: "yoga",
            title: "Complete the weekly target",
            status: +this.weeklyTargets.yoga,
            url: ["/yoga"],
          });
          if (this.goalStatus.yoga == 0) {
            this.weeklyTasks.push({
              area: "yoga",
              icon: "yoga",
              title: "Add a goal",
              status: 0,
              url: ["/yoga", "add-goal"],
            });
          }
          if (this.goalStatus.yoga == 1) {
            this.weeklyTasks.push({
              area: "yoga",
              icon: "yoga",
              title: "Add a goal",
              status: 1,
              url: ["/yoga", "add-goal"],
            });
            this.weeklyTasks.push({
              area: "yoga",
              icon: "yoga",
              title: "Update goal progress",
              status: 0,
              url: ["/yoga"],
            });
          }
          if (this.goalStatus.yoga == 2) {
            this.weeklyTasks.push({
              area: "yoga",
              icon: "yoga",
              title: "Add a goal",
              status: 1,
              url: ["/yoga", "add-goal"],
            });
            this.weeklyTasks.push({
              area: "yoga",
              icon: "yoga",
              title: "Update goal progress",
              status: 1,
              url: ["/yoga"],
            });
          }

          if (this.userSymptoms.length > 0) {
            this.weeklyTasks.push({
              area: "managing",
              icon: "symptoms",
              title: "Update your symptoms",
              status: 1,
              url: ["/managing", "update-symptoms"],
            });
          } else {
            this.weeklyTasks.push({
              area: "managing",
              icon: "symptoms",
              title: "Update your symptoms",
              status: 0,
              url: ["/managing", "update-symptoms"],
            });
          }
        }
      },
      (error) => {
        this.isLoading = false;
        this.errors.push(error.message);
      }
    );
  }

  openTasks() {
    return this.weeklyTasks.filter((task: WeeklyTask) => task.status === 0);
  }

  completeTasks() {
    return this.weeklyTasks.filter((task: WeeklyTask) => task.status === 1);
  }
}
