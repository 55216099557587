import { Goal } from "./../../models/goal.model";
import { forkJoin } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GoalService } from "src/app/services/goal.service";
import Swal from "sweetalert2/src/sweetalert2.js";

import SwiperCore, { SwiperOptions, Pagination } from "swiper";
// install Swiper modules
SwiperCore.use([Pagination]);

@Component({
  selector: "app-goals-area",
  templateUrl: "./goals-area.component.html",
  styleUrls: ["./goals-area.component.scss"],
})
export class GoalsAreaComponent implements OnInit {
  public config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: false,
    pagination: { clickable: true },
    scrollbar: { draggable: false },
  };
  area = "walking";
  isLoading = false;
  public allGoals: Goal[] = [];
  public openGoals: Goal[] = [];
  public completeGoals: Goal[] = [];
  public errors: any = [];
  public quickestGoal: any;
  public goalStreak = 0;

  constructor(
    private goalService: GoalService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    if (this.route.snapshot.data["area"]) {
      this.area = this.route.snapshot.data["area"];
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    forkJoin({
      goals: this.goalService.fetchArea(this.area),
    }).subscribe(
      (responseData) => {
        this.isLoading = false;
        this.allGoals = responseData.goals;
        this.openGoals = this.allGoals.filter((goal) => +goal.status === 0);
        this.completeGoals = this.allGoals.filter((goal) => +goal.status === 1);

        // work out the stats
        this.quickestGoal = this.goalService.getQuickestGoal(this.allGoals);
      },
      (error) => {
        this.errors.push(error.message);
      }
    );
  }

  onCompleteGoal(goal: Goal) {
    this.goalService.update(goal.goal_id, goal.area, 1, goal.goal).subscribe(
      (responseData) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Goal achieved",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          toast: true,
        });
        this.allGoals.forEach((aGoal, index) => {
          if (aGoal.goal_id === goal.goal_id) {
            this.allGoals[index].status = 1;
          }
        });

        this.openGoals = this.allGoals.filter((goal) => +goal.status === 0);
        this.completeGoals = this.allGoals.filter((goal) => +goal.status === 1);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSwiper(swiper: any) {}
  onSlideChange() {}

  onDeleteEntry(entryId: number) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to undo this",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it",
    }).then((result) => {
      if (result.value) {
        this.goalService.delete(entryId).subscribe(
          (responseData) => {
            Swal.fire("Deleted", "Your entry has been deleted.", "success");
            // remove it from the walks..

            let deleteIndex = -1;
            this.allGoals.forEach((goal, goalIndex) => {
              if (+goal.goal_id === +entryId) {
                deleteIndex = goalIndex;
              }
            });

            if (deleteIndex != -1) {
              // splice it
              this.allGoals.splice(deleteIndex, 1);
            }
          },
          (error) => {
            this.isLoading = false;
            this.errors.push(error.message);
            Swal.fire("Deleted!", "There was an error.", "error");
          }
        );
      }
    });
  }
}
