<div class="fh bg-light pb-10 content-container">

  <app-page-header
  *ngIf="area === 'walking'"
    [title]="'Create Walking Goal'"
    [icon]="'walking'"
    [backLink]="['/walking']"
  ></app-page-header>


  <app-page-header
  *ngIf="area === 'strength'"
    [title]="'Create Strength Goal'"
    [icon]="'strength'"
    [backLink]="['/strength']"
  ></app-page-header>


  <app-page-header
  *ngIf="area === 'yoga'"
    [title]="'Create Yoga Goal'"
    [icon]="'yoga'"
    [backLink]="['/yoga']"
  ></app-page-header>


  <div class="row">
    <div class="col">
      <form class="form-recharge" (ngSubmit)="onSubmit()" #form="ngForm">
        <div class="form-group">
          <label for="duration text-center">
            <span class="icon-wrap text-secondary-accent">
              <fa-icon [icon]="['fas', 'star']"></fa-icon>
            </span>
            YOUR GOAL</label
          >
          <div class="col-12">
            <textarea
              id="goal"
              class="form-control"
              ngModel
              #goal="ngModel"
              name="goal"
              placeholder="Enter your text"
              required
              rows="6"
            ></textarea>

          <div
          class="alert alert-danger"
          *ngIf="!goal.valid && submitted"
            >
              Please enter a goal above
          </div>
          </div>
        </div>

        <button class="btn btn-secondary rounded-pill w-100">
          Create Goal
        </button>
      </form>
    </div>
  </div>
</div>
