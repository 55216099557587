import { forkJoin } from "rxjs";
import { AchievementService } from "./../../services/achievement.service";
import { GoalService } from "./../../services/goal.service";
import { DateHelperService } from "./../../services/date-helper.service";
import { UserService } from "./../../auth/service/user.service";
import { YogaWorkoutService } from "./../../services/yoga-workout.service";
import { Goal } from "./../../models/goal.model";
import { UserYogaWorkout } from "./../../models/user-yoga-workout.model";
import { SwiperOptions } from "swiper";
import { Component, OnInit } from "@angular/core";
import Swal from "sweetalert2/src/sweetalert2.js";
import { ResourcesService } from "src/app/services/resources.service";

@Component({
  selector: "app-yoga-index",
  templateUrl: "./yoga-index.component.html",
  styleUrls: ["./yoga-index.component.scss"],
})
export class YogaIndexComponent implements OnInit {
  public config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: false,
    pagination: { clickable: true },
    scrollbar: { draggable: false },
  };
  public isLoading = false;
  public userWorkouts!: UserYogaWorkout[];
  public daysHit = 0;
  public weeklyTarget = this.yogaWorkoutService.weeklyTarget;
  public errors: any = [];
  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public currentWeekData!: any[];
  public today = this.dateHelperService.getToday();
  public allGoals: Goal[] = [];
  public openGoals: Goal[] = [];
  public completeGoals: Goal[] = [];
  public achievements: any[] = [];
  public resourceId = 0;
  public pages?:any[];
  public resource:any;

  constructor(
    private yogaWorkoutService: YogaWorkoutService,
    private userService: UserService,
    private dateHelperService: DateHelperService,
    private goalService: GoalService,
    private achievementService: AchievementService,
    private resourcesService: ResourcesService
  ) {
    this.resourceId = this.resourcesService.resourceIdMap['yoga'];}

  ngOnInit(): void {
    this.isLoading = true;
    const startDate = this.currentWeek[0];
    const endDate = this.currentWeek[6];
    forkJoin({
      userWorkouts: this.yogaWorkoutService.fetchBetween(startDate, endDate),
      userData: this.userService.get(),
      goals: this.goalService.fetchArea("yoga"),
      achievements: this.achievementService.fetchArea('yoga'),
      resource: this.resourcesService.fetchResource(this.resourceId)
    }).subscribe(
      (responseData) => {
        this.isLoading = false;
        this.userWorkouts = responseData.userWorkouts;
        this.currentWeekData = this.yogaWorkoutService.generateWeekArray(
          this.currentWeek,
          this.userWorkouts
        );

        this.daysHit = this.yogaWorkoutService.getDaysHit(this.currentWeekData);
        this.allGoals = responseData.goals;
        this.openGoals = this.allGoals.filter((goal) => +goal.status === 0);
        this.completeGoals = this.allGoals.filter((goal) => +goal.status === 1);
        this.achievements  = this.achievementService.getUnique(responseData.achievements);
        this.resource = responseData.resource.resource;
        this.pages = responseData.resource.pages;
      },
      (error) => {
        this.errors.push(error.message);
      }
    );
  }

  onCompleteGoal(goal: Goal) {
    this.goalService.update(goal.goal_id, goal.area, 1, goal.goal).subscribe(
      (responseData) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Goal achieved",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          toast: true,
        });
        this.allGoals.forEach((aGoal, index) => {
          if (aGoal.goal_id === goal.goal_id) {
            this.allGoals[index].status = 1;
          }
        });

        this.openGoals = this.allGoals.filter((goal) => +goal.status === 0);
        this.completeGoals = this.allGoals.filter((goal) => +goal.status === 1);
        this.achievementService.refreshNotifications();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSwiper(swiper: any) {}
  onSlideChange() {}
}
